// ADDRESS
export const PREFIX_SHIPPING = 'shipping_';
export const PREFIX_BILLING = 'billing_';

// CATEGORY
export const CATEGORY_TROUSERS = 'Trousers';
export const CATEGORY_JACKETS = 'Jackets';
export const CATEGORY_SHIRTS = 'Shirts';
export const CATEGORY_SUITS = 'Suits';
export const CATEGORY_FABRICS = 'Fabrics';
export const CATEGORY_THREE_PIECE_SUITS = 'Three Piece Suits';
export const CATEGORY_WAISTCOATS = 'Waistcoats';

// ORDER - MESSAGE TYPES
export const PRIVATE_MESSAGE = 'PRIVATE_MESSAGE';
export const TAILOR_SHIPPING_MESSAGE = 'TAILOR_SHIPPING_MESSAGE';
export const ADMIN_SHIPPING_MESSAGE = 'ADMIN_SHIPPING_MESSAGE';

// SKU NAMES
export const SKU_DB_JACKET = 'DBC';
export const SKU_SB_JACKET = 'COAT912';
export const SKU_CLASSIC_DRESS_SHIRT = 'CDS';
export const SKU_SB_OFFICE_SUIT = 'OS';
export const SKU_DB_OFFICE_SUIT = 'OS-DB';
export const SKU_3R2_JACKET = '3roll2jacket';

// SLUGS
export const SLUG_TROUSERS = 'trousers';
export const SLUG_JACKETS = 'jackets';
export const SLUG_SHIRTS = 'shirts';
export const SLUG_SUITS = 'suits';

// STRINGS
export const STR_DASHBOARD = 'dashboard';
export const STR_RADIO = 'radio'; // used by OptionsCustomizer
export const STR_CARDS = 'cards'; // used by OptionsCustomizer
export const STR_STORE = 'Shop';

// URLS
export const URL_CART = '/cart';
export const URL_CUSTOMER_DASHBOARD = '/dashboard';
export const URL_FAQ = '/faq';
export const URL_GENIE_PUBLIC = '/genie';
export const URL_HOME = '/';
export const URL_LOGIN_PAGE = '/login';
export const URL_MY_MEASUREMENTS = '/my-measurements';
export const URL_PAYMENT = '/checkout/payment';
export const URL_PROFILE = '/profile';
export const URL_STORE = '/shop';


// URL SLUGS
export const SLUG_ACCOUNT_DETAILS = 'account-details';
export const SLUG_BILLING_ADDRESS = 'billing-address';
export const SLUG_SHIPPING_ADDRESS = 'shipping-address';

// API
export const URL_BLOG = 'https://blog.askokey.com/wp-json/wp/v2/posts';
export const URL_HERO_CAROUSEL_ITEMS = '/api/v1/hero-carousel-items';
export const URL_ORDERS = '/api/v1/orders';
export const URL_ORDER_OVERVIEW = '/api/v1/orders/admin/order-overviews';
export const URL_UNOPENED_TOO_LONG = '/api/v1/orders/admin/too-long/unopened';
export const URL_UNREAD_MESSAGES = '/api/v1/orders/unread-comments';
export const URL_MISSING_MEASUREMENTS_TOO_LONG =
  '/api/v1/orders/admin/too-long/missing-measurements';
export const URL_IN_PROGRESS_TOO_LONG =
  '/api/v1/orders/admin/too-long/in-progress';
