import validate from 'validate.js';
import { isFabricCartItem } from '../store.utils';

export const isValidItem = (item) => {
  const constraints = {
    quantity: { presence: true },
    product: { presence: true },
    'product.price': { presence: true },
    fabric: { presence: true },
    'fabric.default.price': { presence: true },
    'fabric.userChoice.price': { presence: true },
    patternPrice: { presence: true },
  };
  const errors = validate(item, constraints);
  return typeof errors === 'undefined';
};

const getFabricTotal = (item) => {
  return item?.product?.price * item?.meters * item?.quantity;
};
const getGarmentTotal = (item) => {
  if (!isValidItem(item)) return -1;

  return (
    item.quantity *
      (item.product.price -
        item.fabric.default.price +
        item.fabric.userChoice.price) +
    item.patternPrice
  );
};

export const getProductTotal = (item) => {
  if (isFabricCartItem(item)) return getFabricTotal(item);
  return getGarmentTotal(item);
};

export const replaceLocalCartWithServerCart = (localCart, serverCart) => {
  // clear local cart
  localCart.splice(0, localCart.length);

  // insert all items from server cart into local cart
  for (let item of serverCart) {
    localCart.push(item);
  }
};
