import { isEmpty } from 'validate.js';

/**
 * Returns how many measurements do not yet have a value in the 'measurements' array
 * -1   : No values found
 * n    : Number of missing measurements
 * @param {Array} measurements List of measurements
 * @returns Number of missing measurements
 */
export const countOfMissingMeasurements = (measurements) => {
  if (!measurements || measurements.length === 0) {
    return -1;
  }

  const nans = measurements.filter(
    (ele) => isNaN(ele.value) || ele.value === '' || ele.value === null
  );
  return nans.length;
};

/**
 * DEPRECATED FUNCTION
 * Replaced by missing-items.utils.js > getWarningMessage
 * 
 * Returns a custom Message depending on how many measurements are missing
 * @param {Array} measurements List of measurements
 * @returns {String}
 */
export const warningMessageForMeasurements = (measurements) => {
  const count = countOfMissingMeasurements(measurements);
  if (count === 0) return '';
  if (count === -1 || count === measurements.length)
    return 'Measurements are missing';
  return 'Some measurements are missing';
};

// Returns true if NO measurements exist
export const hasNoMeasurements = (measurements) =>
  !measurements || measurements.length === 0;

// Returns true if ALL measurements exist
export const hasAllMeasurements = (measurements) => {
  return countOfMissingMeasurements(measurements) === 0;
};

// Returns true if SOME measurements exist
export const hasPartialMeasurements = (measurements) =>
  !hasNoMeasurements(measurements) && !hasAllMeasurements(measurements);

// Returns true if given measurement object has a value
export const hasValue = (measurement) =>
  !isEmpty(measurement.value) && !isNaN(measurement.value);

// Returns a single array containing both product questions and measurement group questions
export const getMeasurementQuestionsForProduct = (product) => [
  ...product.primaryCategoryMeasurementQuestions,
  ...product.measurementGroupQuestions,
];