import styled from 'styled-components';

const StyledFlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => props.padding || '0px 16px'};
  text-align: center;
`;

export default StyledFlexRow;
