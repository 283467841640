import React from 'react';
import Modal from '../Modal';
import MeasurementsForm from './MeasurementsForm';

const MeasurementsModal = ({
  isOpen,
  onHide,
  measurements,
  unitOfMeasurement,
}) => {
  const styleOverrides = {
    title: { marginBottom: '0' },
    content: { padding: '8px' },
    size: 'xl',
  };
  return (
    <Modal
      isOpen={isOpen}
      onHide={onHide}
      title='Measurements'
      styleOverrides={styleOverrides}
    >
      <MeasurementsForm
        measurements={measurements}
        unitOfMeasurement={unitOfMeasurement}
      />
    </Modal>
  );
};

export default MeasurementsModal;
