export const clone = (obj) => JSON.parse(JSON.stringify(obj));

export const hasChildren = (obj) => Object.keys(obj).length > 0;

// Returns true if obj is an Object
// https://stackoverflow.com/a/22482737
export const isObject = (obj) => obj === Object(obj);

export const getJSON = (text) => {
  try {
    return JSON.parse(text);
  } catch {
    return null;
  }
};

/**
 * Returns true if given object has given key
 * @param {Object} obj 
 * @param {String} key 
 * @returns Boolean
 */
export const hasKey = (obj, key) => obj?.hasOwnProperty(key);