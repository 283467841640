import { combineReducers } from 'redux';

import {
  PC_CLEAR_CUSTOMIZATION,
  PC_SET_DEFAULT_FABRIC,
  PC_SET_USER_CHOICE_FABRIC,
} from '../productCustomization.constants';

const initialState = {};
const defaultReducer = (state = initialState, action) => {
  switch (action.type) {
    case PC_SET_DEFAULT_FABRIC:
      return action.payload;
    case PC_CLEAR_CUSTOMIZATION:
      return {};
    default:
      return state;
  }
};
const userChoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case PC_SET_USER_CHOICE_FABRIC:
      return action.payload;
    case PC_CLEAR_CUSTOMIZATION:
      return {};
    default:
      return state;
  }
};
export default combineReducers({
  default: defaultReducer,
  userChoice: userChoiceReducer,
});
