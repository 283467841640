import { isEmpty } from 'validate.js';

const useOptions = (options = {}) => {
  const {
    dbCoatButtons,
    dbPocketStyle,
    dbCuffButtons,
    coatButtons,
    pocketStyle,
    trouserCuffs,
    shirtCuff,
    shirtChestPocket,
    osCuffButtons,
    osPocketStyle,
    osTrouserCuffs,
    dbOSCoatButtons,
    dbOSCuffButtons,
    dbOSTrouserCuffs,
    threeRollTwoCuffButtons,
    threeRollTwoPocketStyle,
  } = options;

  const isJacket =
    !isEmpty(coatButtons) ||
    !isEmpty(dbCoatButtons) ||
    !isEmpty(threeRollTwoPocketStyle);
  const isTrouser = !isEmpty(trouserCuffs);
  const isShirt = !isEmpty(shirtChestPocket);
  const isOfficeSuit = !isEmpty(osCuffButtons) || !isEmpty(dbOSCoatButtons);

  let subtitle = '';
  if (isJacket) subtitle = 'Jacket options';
  if (isTrouser) subtitle = 'Trouser options';
  if (isShirt) subtitle = 'Shirt options';
  if (isOfficeSuit) subtitle = 'Office Suit options';

  return {
    dbOptions: {
      dbCoatButtons,
      dbPocketStyle,
      dbCuffButtons,
    },
    sbOptions: {
      coatButtons,
      pocketStyle,
    },
    trouserOptions: {
      trouserCuffs,
    },
    shirtOptions: {
      shirtCuff,
      shirtChestPocket,
    },
    sbOSOptions: {
      osCuffButtons,
      osPocketStyle,
      osTrouserCuffs,
    },
    dbOSOptions: {
      dbOSCoatButtons,
      dbOSCuffButtons,
      dbOSTrouserCuffs,
    },
    threeRollTwoOptions: {
      threeRollTwoCuffButtons,
      threeRollTwoPocketStyle,
    },
    isJacket,
    isTrouser,
    isShirt,
    isOfficeSuit,
    subtitle,
  };
};

export default useOptions;
