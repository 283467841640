import styled from 'styled-components';

const StyledCenterContentBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: ${(props) => props.flexDirection || 'row'};
  margin: ${(props) => props.margin || 0};
  padding: ${(props) => props.padding || 0};
  text-align: ${(props) => props.textAlign || 'left'};
  width: ${(props) => props.width || 'auto'};
`;
export default StyledCenterContentBox;
