import { useDispatch, useSelector } from 'react-redux';
import { hideSidebar, showSidebar } from '../ui.actions';

const useSidebar = () => {
  const isSidebarOpen = useSelector((state) => state.ui.isSidebarOpen);
  const dispatch = useDispatch();

  const dispatchShowSidebar = () => {
    dispatch(showSidebar());
  };
  const dispatchHideSidebar = () => {
    dispatch(hideSidebar());
  };

  const toggleSidebar = () => {
    isSidebarOpen ? dispatchHideSidebar() : dispatchShowSidebar();
  };

  // Return array instead of object because we want the caller to use names showSidebar instead of dispatchShowSidebar
  return [
    isSidebarOpen,
    toggleSidebar,
    dispatchShowSidebar,
    dispatchHideSidebar,
  ];
};

export default useSidebar;
