import React from 'react';

import Box from './Box';
import { Coconut } from '../../typography';
import StyledContainer from '../../styled/StyledContainer';
import StyledPageTopper from '../styled/StyledPageTopper';
import theme from '../../theme';

const PageTopper = ({ title }) => {
  return (
    <>
      <StyledPageTopper />
      <Box backgroundColor={theme.colors.text.light}>
        <StyledContainer>
          <Coconut>{title}</Coconut>
        </StyledContainer>
      </Box>
    </>
  );
};

export default PageTopper;
