import React from 'react';

import Header from './Header';

import { Box } from '../../../../ui-library/layout';
import CartTotal from '../../../store/cart/CartTotal';
import Toolbar from './Toolbar';
import useCart from '../../../store/cart/useCart';

const CartPreview = () => {
  const { cart } = useCart();

  return (
    <Box
      flexDirection='column'
      justifyContent='space-between'
      className='full-width full-height'
      padding='32px 16px'
    >
      <Header />
      <div className='full-width'>
        <CartTotal cart={cart} />
        <Toolbar />
      </div>
    </Box>
  );
};

export default CartPreview;
