import React from 'react';
import { ReactComponent as Logo } from '../assets/btn_google_signin_dark_normal_web.svg';

const GoogleLoginButton = ({ redirectURL, callback }) => {
  const handleClick = () => {
    const authenticateURL = `/auth/google?redirect=${redirectURL}`;
    window.location.href = authenticateURL;
    callback();
  };
  return (
    <div onClick={handleClick} style={{ cursor: 'pointer' }}>
      <Logo />
    </div>
  );
};

export default GoogleLoginButton;
