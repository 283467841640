import {
  PC_CLEAR_CUSTOMIZATION,
  PC_CLEAR_PHOTOS,
  PC_SET_PHOTO,
  PC_SET_ALL_PHOTOS,
} from '../productCustomization.constants';

/*
Shape
[
  {title:'FRONT', imageURL:'https://...'},
]
*/

const initialState = [
  { title: 'Front' },
  { title: 'Back' },
  { title: 'Left' },
  { title: 'Right' },
];
const photosReducer = (state = initialState, action) => {
  const photo = action.payload;
  switch (action.type) {
    case PC_SET_PHOTO:
      const ns = state.map((ele) => (ele.title === photo.title ? photo : ele));
      return ns;
    case PC_SET_ALL_PHOTOS:
      return action.payload;
    case PC_CLEAR_PHOTOS:
      return initialState;
    case PC_CLEAR_CUSTOMIZATION:
      return initialState;
    default:
      return state;
  }
};

export default photosReducer;
