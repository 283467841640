export const identify = (userId, properties) => {
  window.analytics.identify(userId, properties);
};

export const track = (event, properties) => {
  // comment this out
  // console.log('tracking.js > track.event', event);
  // console.log('tracking.js > track.properties', properties);

  window.analytics.track(event, properties);

  // alternate method of tracking
  // window.analytics.track({
  //   messageId: 'app-segment-test-message-5l0anr',
  //   timestamp: '2021-12-17T16:33:02.905Z',
  //   type: 'track',
  //   email: 'test@example.org',
  //   projectId: 'uPerzLvirNfnACrwNgVj2N',
  //   properties: {
  //     property1: 1,
  //     property2: 'test',
  //     property3: true,
  //   },
  //   userId: 'test-user-0e0sfk',
  //   event: 'App Test Event Name',
  // });
};
