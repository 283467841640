import {
  OB_SIGN_UP_CLICK,
  OB_GOOGLE_SSO_CLICK,
  OB_CLOSE_BTN_CLICK,
  OB_SUBMIT_CLICK,
  OB_LOGIN_LINK_CLICK,
} from './onboarding.tracking.constants';
import { track } from './tracking';

export const trackSignUpClick = () => {
  track(OB_SIGN_UP_CLICK);
};

export const trackGoogleSSOClick = () => {
  track(OB_GOOGLE_SSO_CLICK);
};

export const trackCloseBtnClick = () => {
  track(OB_CLOSE_BTN_CLICK);
};

export const trackSubmitClick = () => {
  track(OB_SUBMIT_CLICK);
};

export const trackLoginLinkClick = () => {
  track(OB_LOGIN_LINK_CLICK);
};
