import { useState } from 'react';

import {
  AUTH_LOGIN_UI,
  AUTH_SIGNUP_UI,
  AUTH_RESET_PASSWORD_EMAIL_SENT_UI,
  AUTH_REQUEST_RESET_PASSWORD_UI,
} from './local-auth.constants';

/**
 * Changes and maintains UI state. currentUI be one of 4 options:
 * AUTH_LOGIN_UI  : Show Login UI
 * AUTH_SIGNUP_UI : Show Signup UI
 * AUTH_REQUEST_RESET_PASSWORD_UI : Show 'Reset Password' form
 * AUTH_RESET_PASSWORD_EMAIL_SENT_UI : Show message after reset email has been sent
 *
 * @param {String} initialUI The UI option to start with.
 *        Will be either AUTH_LOGIN_UI or AUTH_SIGNUP_UI
 * @param {Function} currentUICallback Function to call after currentUI has been changed
 *        This is needed to inform LoginRegisterModal when currentUI changes
 *        so that it may change the modal's header title
 * @param {Function} setError Set / Clear error display in UI
 */
const useCurrentUI = ({ initialUI, currentUICallback, setError }) => {
  const [currentUI, setCurrentUI] = useState(initialUI);

  const propagateCurrentUIChange = (val) => {
    // if callback has been provided, channel the state change to it
    if (currentUICallback) {
      currentUICallback(val);
    }
  };

  const changeCurrentUI = (val) => {
    setCurrentUI(val);
    propagateCurrentUIChange(val);
    setError(null);
  };

  // Handler for clicking on 'Create account'
  const handleWantToSignup = () => {
    changeCurrentUI(AUTH_SIGNUP_UI);
  };

  // Handler for clicking on 'login'
  const handleWantToLogin = () => {
    changeCurrentUI(AUTH_LOGIN_UI);
  };

  // Handler for clicking on 'Forgot Password'
  const handleWantToResetPassword = () => {
    changeCurrentUI(AUTH_REQUEST_RESET_PASSWORD_UI);
  };

  // Handler after 'Forgot Password' email has been sent
  const handleSentResetPasswordEmail = () => {
    changeCurrentUI(AUTH_RESET_PASSWORD_EMAIL_SENT_UI);
  };

  return {
    currentUI,
    handleWantToLogin,
    handleWantToSignup,
    handleWantToResetPassword,
    handleSentResetPasswordEmail,
  };
};

export default useCurrentUI;
