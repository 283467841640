import React from 'react';
import { useEffect } from 'react';

//Zoho Sales Iq Script:
const useScript = (url, widgetCode) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');

    let code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "siq723f7b24959ffc782a1e362445e7e6ecc32c554d0abb0c6fadfc69a6182fcb8a", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zohopublic.com/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqwidget'></div>";`;

    script.appendChild(document.createTextNode(code));
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export default function SalesIQ() {
  return (
    <React.Fragment>
      {useScript(
        'https://salesiq.zoho.com/widget',
        'siq723f7b24959ffc782a1e362445e7e6ecc32c554d0abb0c6fadfc69a6182fcb8a'
      )}
    </React.Fragment>
  );
}
