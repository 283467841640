import React from 'react';
import StyledBaseText from '../styled/StyledBaseText';
import theme from '../../theme';
const { mango } = theme.textStyles;

const Mango = (props) => {
  return (
    <StyledBaseText {...mango} {...props}>
      {props.children}
    </StyledBaseText>
  );
};

export default Mango;
