import { COUPON_CLEAR_COUPON, COUPON_SET_COUPON } from './coupon.constants';

const initialState = {};
const couponReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case COUPON_SET_COUPON:
      return {
        code: payload.code,
        value: payload.value,
        valueType: payload.valueType,
      };
    case COUPON_CLEAR_COUPON:
      return {
        error: payload,
      };
    default:
      return state;
  }
};

export default couponReducer;
