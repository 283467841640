import styled from 'styled-components';

const getPadding = (props) => {
  if (props.padding) return props.padding;
  if (props.noBottomPadding) return `12px 12px 0px 12px`;
  return `12px`;
};
const getBorder = (props) => {
  if (props.border) return props.border;
  const thickness = props.thinBorder ? '1px' : '2px';
  return `${thickness} solid ${
    props.variant === 'dark'
      ? props.theme.colors.grey.main
      : props.theme.colors.grey.light
  }`;
};
const StyledRoundedBorder = styled.div`
  background-color: ${(props) => props.fill || props.theme.colors.white};
  border: ${(props) => getBorder(props)};
  border-radius: ${(props) => props.borderRadius || '8px'};
  box-shadow: ${(props) =>
    props.shadow ? '0 0 21px rgba(33, 33, 33, 0.1)' : 0};
  margin: ${(props) => props.margin || 0};
  padding: ${(props) => getPadding(props)};
`;

export default StyledRoundedBorder;
