import React from 'react';
import StyledBaseText from '../styled/StyledBaseText';
import theme from '../../theme';
const { venus } = theme.textStyles;

const Venus = (props) => {
  return (
    <StyledBaseText {...venus} {...props}>
      {props.children}
    </StyledBaseText>
  );
};

export default Venus;
