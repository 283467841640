import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { fetchUser } from '../auth.actions';
import { loginSignedUpUser, signup } from './local-auth.utils';
import useNavigation from '../../../utils/useNavigation';

/**
 * Handles all logic for LocalAuth - login and signup
 *
 * @param {String} redirectURL URL to redirect to on successful login / signup
 * @param {Function} onHide Containing modal's hide function
 *        Called on successful login / signup
 * @param {Function} setError Caller's error display function
 */
const useLocalAuth = ({ redirectURL, onHide, setError }) => {
  // Used to show spinner when user has clicked login, signup or reset password
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const dispatch = useDispatch();
  const { goToURL } = useNavigation();

  // signup and loginSignedUpUser return a status code for success or error
  // We process the status and alter the UI accordingly
  // Success will hide the modal
  // Error will set the error message
  const handleStatus = (status) => {
    if (status.success) {
      dispatch(fetchUser());
      goToURL(redirectURL);
      onHide();
    }
    if (status.error) {
      setError(status.error);
    }
    setIsLoggingIn(false);
    setIsSigningUp(false);
  };

  // Logs the user into the system
  // Handles API return value for success and failure
  const handleSubmitLogin = async (values) => {
    setIsLoggingIn(true);
    setError(null);

    const status = await loginSignedUpUser(values);
    handleStatus(status);
  };

  // Creates account for user in the system
  // Handles API return value for success and failure
  const handleSubmitSignup = async (values) => {
    setIsSigningUp(true);
    setError(null);

    const status = await signup(values);
    handleStatus(status);
  };

  return {
    handleSubmitLogin,
    handleSubmitSignup,
    isLoggingIn,
    isSigningUp,
  };
};

export default useLocalAuth;
