/**
 * Returns number as a 'Comma Separated Number'
 * Eg: 90000 => 90,000
 *
 * @param {Number} val
 * @returns String
 */
export const getCSN = (val) => Number(val).toLocaleString();

/**
 * Returns string as float
 * Eg: 90,000 => 90000
 *
 * @param {String} str
 * @returns Number
 */
export const getFloat = (str) => parseFloat(str.replace(/,/g, ''));
