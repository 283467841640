import {
  SI_FORGOT_PWD_LINK_CLICK,
  SI_NEED_ACCOUNT_CLICK,
  SI_SEND_RESET_PWD_CLICK,
  SI_RETURN_TO_LOGIN_CLICK,
} from './signin.tracking.constants';
import { track } from './tracking';

export const trackForgotPasswordLinkClick = () => {
  track(SI_FORGOT_PWD_LINK_CLICK);
};

export const trackNeedAccountClick = () => {
  track(SI_NEED_ACCOUNT_CLICK);
};

export const trackRequestResetPasswordClick = () => {
  track(SI_SEND_RESET_PWD_CLICK);
};

export const trackReturnToLoginClick = () => {
  track(SI_RETURN_TO_LOGIN_CLICK);
};
