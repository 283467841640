import React from 'react';
import Button from './Button';

const TextButton = ({ children, ...rest }) => {
  return (
    <Button
      isText
      size='xs'
      fontWeight='300'
      textDecoration='underline'
      textTransform='none'
      letterSpacing='0.005em'
      {...rest}
    >
      {children}
    </Button>
  );
};

export default TextButton;
