import React from 'react';
import StyledHugRight, { StyledColumn } from '../styled/StyledHugRight';

/**
 * Column stays hugging to the right. Restricts column width to maxWidth irrespective of browser width. Used for the 1st column in CartPage and CheckoutPage
 * @param {String} maxWidthDesktop Eg '400px' Max Width for this column in Desktop
 * @param {String} maxWidthMobile Eg '400px' Max Width for this column in Mobile
 */
const HugRight = ({ children, maxWidthDesktop, maxWidthMobile }) => (
  <StyledHugRight>
    <StyledColumn
      maxWidthDesktop={maxWidthDesktop}
      maxWidthMobile={maxWidthMobile}
    >
      {children}
    </StyledColumn>
  </StyledHugRight>
);

export default HugRight;
