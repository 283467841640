import React from 'react';
import { Button } from '../../../ui-library/buttons';
import { CartIconLight } from '../../../ui-library/icons';
import VerticalCenterContentBox from '../../../ui-library/layout/elements/VerticalCenterContentBox';
import useNavigation from '../../../utils/useNavigation';

const EmptyCart = ({ onStartShopping }) => {
  const { goToStore } = useNavigation();

  const handleClick = () => {
    onStartShopping ? onStartShopping() : goToStore();
  };
  return (
    <VerticalCenterContentBox>
      <CartIconLight />
      <div style={{ marginTop: '6px', marginBottom: '22px' }}>
        Shopping bag is empty
      </div>
      <Button onClick={handleClick}>Start Shopping</Button>
    </VerticalCenterContentBox>
  );
};

export default EmptyCart;
