import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import NumberFormat from 'react-number-format';

// Floating label logic taken from here:
// https://dev.to/rafacdomin/creating-floating-label-placeholder-for-input-with-reactjs-4m1f

const propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
};

const hasValue = (input) => input.value !== '';

const shouldShowError = (meta) => meta.error && meta.touched;

const getLabelClassName = (input, disabled) => {
  if (disabled) {
    // currently, we assume that if it is disabled it has a value (Since we're only using it in AddressModal to show the customer's shipping address). This is why the -value-exists class name is also being added. (Without this, the label will overlap the input control's text)
    return 'form-field-input-value-exists form-field-label-disabled';
  }
  if (hasValue(input)) {
    return 'form-field-input-value-exists';
  }
  return '';
};

const getNumberFormatClassName = (meta, disabled) => {
  if (disabled) return 'form-field-input-disabled';
  if (shouldShowError(meta)) return 'form-field-input-error';
  return 'form-field-input-regular';
};

const FormNumberField = ({
  name,
  title,
  decimalScale = '2',
  fixedDecimalScale = true,
  disabled,
  ...rest
}) => {
  return (
    <Field
      name={name}
      subscribe={{ touched: true, error: true }}
      render={({ input, meta }) => {
        return (
          <div className='form-field-container '>
            <NumberFormat
              {...rest}
              value={input.value}
              decimalScale={decimalScale}
              fixedDecimalScale={fixedDecimalScale}
              allowNegative={false}
              allowLeadingZeros
              autoComplete='off'
              onBlur={input.onBlur}
              onFocus={input.onFocus}
              onChange={input.onChange}
              onValueChange={input.onChange}
              className={getNumberFormatClassName(meta, disabled)}
              disabled={disabled}
            />
            <label className={getLabelClassName(input, disabled)}>
              <div className='form-field-label-background'>{title || name}</div>
            </label>
          </div>
        );
      }}
    />
  );
};

FormNumberField.propTypes = propTypes;

export default FormNumberField;
