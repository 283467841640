import { combineReducers } from 'redux';

import fabricReducer from './fabric/fabric.reducers';
import measurementsReducer from './measurement/measurement.reducers';
import optionsReducer from './options/options.reducers';
import patternPriceReducer from './pattern/patternPrice.reducers';
import photosReducer from './photos/photos.reducers';
import unitOfMeasurementReducer from './measurement/unitOfMeasurement.reducers';

export default combineReducers({
  options: optionsReducer,
  fabric: fabricReducer,
  patternPrice: patternPriceReducer,
  photos: photosReducer,
  measurements: measurementsReducer,
  unitOfMeasurement: unitOfMeasurementReducer,
});
