import {
  PC_CLEAR_CUSTOMIZATION,
  PC_TAB_STATUS_AWAITING,
  PC_TAB_STATUS_COMPLETED,
  PC_TAB_STATUS_SELECTED,
} from '../store/customize-product/productCustomization.constants';
import {
  UI_BEGIN_SELECTING_OPTIONS,
  UI_END_SELECTING_OPTIONS,
  UI_BEGIN_CUSTOMIZING_FABRIC,
  UI_BEGIN_ENTERING_MEASUREMENTS,
  UI_BEGIN_UPLOADING_PHOTOS,
  UI_END_CUSTOMIZING_FABRIC,
  UI_END_ENTERING_MEASUREMENTS,
  UI_BEGIN_REVIEWING_ITEM,
  UI_END_REVIEWING_ITEM,
  UI_SET_TAB_STATUS,
  UI_END_UPLOADING_PHOTOS,
  UI_HIDE_SIDEBAR,
  UI_SHOW_SIDEBAR,
} from './ui.constants';

const initialState = {
  isSidebarOpen: false,
  isSelectingOptions: true,
  isCustomizingFabric: false,
  isEnteringMeasurements: false,
  isUploadingPhotos: false,
  isReviewingItem: false,
  tabsStatus: [
    PC_TAB_STATUS_SELECTED,
    PC_TAB_STATUS_AWAITING,
    PC_TAB_STATUS_AWAITING,
    PC_TAB_STATUS_AWAITING,
    PC_TAB_STATUS_AWAITING,
  ],
};

const uiReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case UI_BEGIN_SELECTING_OPTIONS:
      return {
        ...state,
        isSelectingOptions: true,
      };
    case UI_END_SELECTING_OPTIONS:
      return {
        ...state,
        isSelectingOptions: false,
      };
    case UI_BEGIN_CUSTOMIZING_FABRIC:
      return {
        ...state,
        isCustomizingFabric: true,
      };
    case UI_END_CUSTOMIZING_FABRIC:
      return {
        ...state,
        isCustomizingFabric: false,
      };
    case UI_BEGIN_ENTERING_MEASUREMENTS:
      return {
        ...state,
        isEnteringMeasurements: true,
      };
    case UI_END_ENTERING_MEASUREMENTS:
      return {
        ...state,
        isEnteringMeasurements: false,
      };
    case UI_BEGIN_REVIEWING_ITEM:
      return {
        ...state,
        isReviewingItem: true,
      };
    case UI_END_REVIEWING_ITEM:
      return {
        ...state,
        isReviewingItem: false,
      };
    case UI_SET_TAB_STATUS:
      return {
        ...state,
        tabsStatus: payload,
      };
    case UI_BEGIN_UPLOADING_PHOTOS:
      return {
        ...state,
        isUploadingPhotos: true,
      };
    case UI_END_UPLOADING_PHOTOS:
      return {
        ...state,
        isUploadingPhotos: false,
      };
    case UI_SHOW_SIDEBAR:
      return {
        ...state,
        isSidebarOpen: true,
      };
    case UI_HIDE_SIDEBAR:
      return {
        ...state,
        isSidebarOpen: false,
      };
    case PC_CLEAR_CUSTOMIZATION:
      return initialState;
    default:
      return state;
  }
};

export default uiReducer;
