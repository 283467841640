import React, { useState } from 'react';

/*
  Hook to wrap modal state around a React element
  Values are returned in an array 
    Modal: Original incoming React element. Ideally, this should be a React bootstrap <Modal>. NOTE: This hook assumes the incoming element is a modal and only augments it with a modal STATE.
    showModal: boolean to show or hide the modal
    show: method to show the modal
    hide: method to hide the modal
  
  HOW TO USE:
    const [Modal, isOpen, show, hide] = useModalState(LoginRegisterModal);
  
  show() and hide() can be called to toggle the modal 'LoginRegisterModal'
  isOpen is the boolean we pass to Modal as <Modal show={isOpen} ... /> as required by bootstrap modals
*/
const useModalState = (Element) => {
  const [showModal, setShowModal] = useState(false);

  const show = () => setShowModal(true);
  const hide = () => setShowModal(false);

  const Modal = (props) => <Element {...props} />;

  return [Modal, showModal, show, hide];
};

export default useModalState;
