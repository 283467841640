export const ORDER_STATUS_INCOMPLETE = 'ORDER_STATUS_INCOMPLETE';
export const ORDER_STATUS_UNOPENED = 'ORDER_STATUS_UNOPENED';
export const ORDER_STATUS_IN_PROGRESS = 'ORDER_STATUS_IN_PROGRESS';
export const ORDER_STATUS_COMPLETED = 'ORDER_STATUS_COMPLETED';
export const ORDER_STATUS_OUT_FOR_DELIVERY = 'ORDER_STATUS_OUT_FOR_DELIVERY';
export const ORDER_STATUS_DELIVERED = 'ORDER_STATUS_DELIVERED';
export const ORDER_STATUS_REFUNDED = 'ORDER_STATUS_REFUNDED';
export const ORDER_STATUS_CANCELLED = 'ORDER_STATUS_CANCELLED';

export const DAYS_TO_WAIT_FOR_MEASUREMENTS = 3;
