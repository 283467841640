import React from 'react';
import StyledBaseText from '../styled/StyledBaseText';
import theme from '../../theme';
const { banana } = theme.textStyles;

const Banana = (props) => {
  return (
    <StyledBaseText {...banana} {...props}>
      {props.children}
    </StyledBaseText>
  );
};

export default Banana;
