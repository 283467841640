import axios from 'axios';
import { isEmpty } from 'validate.js';
import {
  URL_IN_PROGRESS_TOO_LONG,
  URL_MISSING_MEASUREMENTS_TOO_LONG,
  URL_ORDERS,
  URL_UNOPENED_TOO_LONG,
  URL_UNREAD_MESSAGES,
} from '../../utils/constants';
import { getPaginatedURL } from '../../utils/url';
import {
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_DELIVERED,
  ORDER_STATUS_INCOMPLETE,
  ORDER_STATUS_IN_PROGRESS,
  ORDER_STATUS_OUT_FOR_DELIVERY,
  ORDER_STATUS_UNOPENED,
} from '../store/checkout/order-confirmation/orderConfirmation.constants';
import {
  ADMIN_IN_PROGRESS_FOR_TOO_LONG_ORDER_LIST_FAIL,
  ADMIN_IN_PROGRESS_FOR_TOO_LONG_ORDER_LIST_REQUEST,
  ADMIN_IN_PROGRESS_FOR_TOO_LONG_ORDER_LIST_SUCCESS,
  ADMIN_MISSING_MEASUREMENTS_FOR_TOO_LONG_ORDER_LIST_FAIL,
  ADMIN_MISSING_MEASUREMENTS_FOR_TOO_LONG_ORDER_LIST_REQUEST,
  ADMIN_MISSING_MEASUREMENTS_FOR_TOO_LONG_ORDER_LIST_SUCCESS,
  ADMIN_UNOPENED_FOR_TOO_LONG_ORDER_LIST_FAIL,
  ADMIN_UNOPENED_FOR_TOO_LONG_ORDER_LIST_REQUEST,
  ADMIN_UNOPENED_FOR_TOO_LONG_ORDER_LIST_SUCCESS,
  CHANNEL_USER_ORDER_UPDATED,
  ORDER_CONDENSER_STATUS,
  ORDER_LIST_BY_STATUS_FAIL,
  ORDER_LIST_BY_STATUS_REQUEST,
  ORDER_LIST_BY_STATUS_SUCCESS,
  USER_ORDER_ADD_MESSAGE,
  USER_ORDER_FAIL,
  USER_ORDER_LIST_FAIL,
  USER_ORDER_LIST_REQUEST,
  USER_ORDER_LIST_SUCCESS,
  USER_ORDER_REQUEST,
  USER_ORDER_SUCCESS,
  USER_UNREAD_MESSAGES_ORDER_LIST_FAIL,
  USER_UNREAD_MESSAGES_ORDER_LIST_REQUEST,
  USER_UNREAD_MESSAGES_ORDER_LIST_SUCCESS,
} from './order.constants';
import orders from './order.test-data';

/**
 * Returns paginated URL for getting order list
 * eg: /api/v1/orders?page=3&filter=something
 * @param {Number} page
 * @param {String} filter
 * @returns String
 */
export const getPaginatedOrderListURL = (page, filter) => {
  let url = getPaginatedURL({ url: URL_ORDERS, page, filter });
  return url;
};

export const getOrderList = (page) => async (dispatch) => {
  try {
    dispatch({ type: USER_ORDER_LIST_REQUEST });
    const url = getPaginatedOrderListURL(page);
    const { data } = await axios.get(url);
    // const data = orders; // test data
    dispatch({ type: USER_ORDER_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: USER_ORDER_LIST_FAIL,
      payload: message,
    });
  }
};

export const getUnopenedOrderList = (page) => async (dispatch) => {
  _getOrderListByStatus({ page, status: ORDER_STATUS_UNOPENED, dispatch });
};

export const getUnreadMessagesOrderList = (page) => async (dispatch) => {
  try {
    dispatch({ type: USER_UNREAD_MESSAGES_ORDER_LIST_REQUEST });
    const url = getPaginatedURL({
      url: URL_UNREAD_MESSAGES,
      page: page,
    });
    const { data } = await axios.get(url);
    // const data = orders; // test data
    dispatch({ type: USER_UNREAD_MESSAGES_ORDER_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: USER_UNREAD_MESSAGES_ORDER_LIST_FAIL,
      payload: message,
    });
  }
};

const getPaginatedOrderListByStatusURL = (page, status, filter) => {
  const urlToUse = {};
  urlToUse[ORDER_STATUS_INCOMPLETE] = `${URL_ORDERS}/on-hold`;
  urlToUse[ORDER_STATUS_UNOPENED] = `${URL_ORDERS}/unopened`;
  urlToUse[ORDER_STATUS_IN_PROGRESS] = `${URL_ORDERS}/in-progress`;
  urlToUse[ORDER_STATUS_COMPLETED] = `${URL_ORDERS}/completed`;
  urlToUse[
    ORDER_STATUS_OUT_FOR_DELIVERY
  ] = `${URL_ORDERS}/admin/out-for-delivery`;
  urlToUse[ORDER_STATUS_DELIVERED] = `${URL_ORDERS}/delivered`;

  let url = getPaginatedURL({
    url: urlToUse[status],
    page: page,
    filter,
  });
  return url;
};

/**
 * Gets and dispatches the paginated order list for given status
 * Function is generic and is used by
 *    - getOrderListByStatus
 *    - getUnopenedOrderList
 * @param {Number} page Page (from pagination) required
 * @param {String} status ORDER_STATUS_XXX
 * @param {Object} dispatch Redux dispatch object
 */
const _getOrderListByStatus = async ({ page, status, dispatch }) => {
  try {
    dispatch({ type: ORDER_LIST_BY_STATUS_REQUEST });
    const url = getPaginatedOrderListByStatusURL(page, status);
    const { data } = await axios.get(url);
    // const data = orders; // test data
    dispatch({
      type: ORDER_LIST_BY_STATUS_SUCCESS,
      payload: { ...data, status },
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: ORDER_LIST_BY_STATUS_FAIL,
      payload: message,
    });
  }
};

export const getOrderListByStatus = (page, status) => async (dispatch) => {
  _getOrderListByStatus({ page, status, dispatch });
};

/**
 * Returns URL to GET single order by order number
 * @param {String} orderNumber
 * @returns String
 */
export const getOrderByOrderNumberURL = (orderNumber) =>
  `${URL_ORDERS}/${orderNumber}`;

/**
 * Gets single order by order number
 * @param {String} orderNumber
 */
export const getOrderByOrderNumber = (orderNumber) => async (dispatch) => {
  try {
    dispatch({ type: USER_ORDER_REQUEST });
    const url = getOrderByOrderNumberURL(orderNumber);
    const { data } = await axios.get(url);
    dispatch({ type: USER_ORDER_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: USER_ORDER_FAIL,
      payload: message,
    });
  }
};

/**
 * Adds given payload to local store
 * payload could be {text, order, user} or {mediaURL, order, user}
 * @param {Object} payload Message
 */
export const addMessageToOrderInLocalStore = (payload) => async (dispatch) => {
  dispatch({ type: USER_ORDER_ADD_MESSAGE, payload });
};

/**
 * Posts user chat message to given order
 * @param {Object} payload {text, mediaURL, order, type}
 */
export const addMessageToOrder = (payload) => async () => {
  const { text, mediaURL, order, type } = payload;
  const endpoint = `/api/v1/orders/${order.orderNumber}/messages`;
  axios.post(endpoint, { text, mediaURL, type }).catch((err) => {
    return err.response ? err.response.data : err;
  });
};

/**
 * Sets order condenser status to given value
 * @param {String} status Order Status
 */
export const setOrderCondenserStatus = (status) => async (dispatch) => {
  dispatch({ type: ORDER_CONDENSER_STATUS, payload: status });
};

/**
 * Sets order status to 'completed' for given order/node
 * @param {Object} payload Object with keys order and node
 */
export const updateOrderStatus = (payload) => async () => {
  const { order, node } = payload;
  const endpoint = `/api/v1/orders/${order.orderNumber}/status`;
  axios.patch(endpoint, { node }).catch((err) => {
    return err.response ? err.response.data : err;
  });
};

// DEPRECATED
export const updateOrder = (payload) => async (dispatch) => {
  dispatch({ type: CHANNEL_USER_ORDER_UPDATED, payload });
};

/**
 * Updates order stats (customerViewedAt, tailorViewedAt, adminViewedAt)
 * @param {Object} payload order
 */
export const updateOrderStats = (payload) => async (dispatch) => {
  if (isEmpty(payload)) return;

  const { orderNumber } = payload;
  if (isEmpty(orderNumber)) return;

  const endpoint = `/api/v1/orders/${orderNumber}/stats/opened`;
  axios.patch(endpoint).catch((err) => {
    return err.response ? err.response.data : err;
  });
};

/***************************************************************
 * ADMIN ACTIONS
 ***************************************************************/
export const getUnopenedForTooLongOrderList = (page) => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_UNOPENED_FOR_TOO_LONG_ORDER_LIST_REQUEST });
    const url = URL_UNOPENED_TOO_LONG;
    const { data } = await axios.get(url);

    dispatch({
      type: ADMIN_UNOPENED_FOR_TOO_LONG_ORDER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: ADMIN_UNOPENED_FOR_TOO_LONG_ORDER_LIST_FAIL,
      payload: message,
    });
  }
};

export const getMissingMeasurementsForTooLongOrderList =
  (page) => async (dispatch) => {
    try {
      dispatch({
        type: ADMIN_MISSING_MEASUREMENTS_FOR_TOO_LONG_ORDER_LIST_REQUEST,
      });
      const url = URL_MISSING_MEASUREMENTS_TOO_LONG;
      const { data } = await axios.get(url);
      dispatch({
        type: ADMIN_MISSING_MEASUREMENTS_FOR_TOO_LONG_ORDER_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: ADMIN_MISSING_MEASUREMENTS_FOR_TOO_LONG_ORDER_LIST_FAIL,
        payload: message,
      });
    }
  };

export const getInProgressForTooLongOrderList = (page) => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_IN_PROGRESS_FOR_TOO_LONG_ORDER_LIST_REQUEST });
    const url = URL_IN_PROGRESS_TOO_LONG;
    const { data } = await axios.get(url);
    dispatch({
      type: ADMIN_IN_PROGRESS_FOR_TOO_LONG_ORDER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: ADMIN_IN_PROGRESS_FOR_TOO_LONG_ORDER_LIST_FAIL,
      payload: message,
    });
  }
};