import produce from 'immer';
import { isArray, isEmpty } from 'validate.js';
import { getIndexOfObjectInListByKey } from '../../utils/array';
import {
  ADMIN_IN_PROGRESS_FOR_TOO_LONG_ORDER_LIST_FAIL,
  ADMIN_IN_PROGRESS_FOR_TOO_LONG_ORDER_LIST_REQUEST,
  ADMIN_IN_PROGRESS_FOR_TOO_LONG_ORDER_LIST_SUCCESS,
  ADMIN_MISSING_MEASUREMENTS_FOR_TOO_LONG_ORDER_LIST_FAIL,
  ADMIN_MISSING_MEASUREMENTS_FOR_TOO_LONG_ORDER_LIST_REQUEST,
  ADMIN_MISSING_MEASUREMENTS_FOR_TOO_LONG_ORDER_LIST_SUCCESS,
  ADMIN_UNOPENED_FOR_TOO_LONG_ORDER_LIST_FAIL,
  ADMIN_UNOPENED_FOR_TOO_LONG_ORDER_LIST_REQUEST,
  ADMIN_UNOPENED_FOR_TOO_LONG_ORDER_LIST_SUCCESS,
  CHANNEL_USER_ORDER_UPDATED,
  ORDER_CONDENSER_STATUS,
  ORDER_LIST_BY_STATUS_FAIL,
  ORDER_LIST_BY_STATUS_REQUEST,
  ORDER_LIST_BY_STATUS_SUCCESS,
  USER_ORDER_ADD_MESSAGE,
  USER_ORDER_FAIL,
  USER_ORDER_LIST_FAIL,
  USER_ORDER_LIST_REQUEST,
  USER_ORDER_LIST_SUCCESS,
  USER_ORDER_REQUEST,
  USER_ORDER_SUCCESS,
  USER_UNREAD_MESSAGES_ORDER_LIST_FAIL,
  USER_UNREAD_MESSAGES_ORDER_LIST_REQUEST,
  USER_UNREAD_MESSAGES_ORDER_LIST_SUCCESS,
} from './order.constants';

const createMessage = (payload) => {
  const { text, mediaURL, user, type } = payload;
  return {
    user_id: user?._id,
    text,
    mediaURL,
    imageURL: user?.imageURL,
    firstName: user?.firstName,
    type,
    createdAt: Date.now(),
  };
};

export const getIndexOfOrderInList = (order, list) =>
  getIndexOfObjectInListByKey(order, list, 'orderNumber');

export const isOrderInList = (order, list) => {
  const index = getIndexOfOrderInList(order, list);
  return index > -1;
};

export const getCombinedOrders = (currentArray, updatedArray) => {
  if (isEmpty(updatedArray) || !isArray(updatedArray)) return currentArray;

  const result = currentArray.concat([]); // make a copy

  updatedArray.forEach((ele) => {
    if (isOrderInList(ele, result)) {
      const index = getIndexOfOrderInList(ele, result);
      result[index] = ele;
    } else {
      result.push(ele);
    }
  });
  return result;
};

/**
 * SHAPE:
 * list: array of ALL orders
 *
 * page is an object that stores the current page number for each state separately (for pagination results).
 *  ALL: This is used for the customer since they currently do not have filters
 *       They always see all orders in the system
 * page: {
 *    ALL: 0
 *    ORDER_STATUS_IN_PROGRESS: 2
 * }
 *
 * hasNextPage: Same logic as page, but to store hasNextPage from pagination results
 *
 *
 * admin is an object where each key stores an array of orders
 * admin: {
 *    unopenedTooLong: [],
 *    missingMeasurementsTooLong: [],
 *    inProgressTooLong: []
 * }
 *
 * condenser filters the orders shown
 *    status: Sets the current order status to display
 *    search: Currently unused
 */
const initialState = {
  list: [],
  page: { ALL: 0 },
  hasNextPage: { ALL: false },
  admin: {},
  condenser: {
    status: null,
    search: null,
  },
};

const orderReducer = produce((draft, action) => {
  const { payload } = action;
  let order, i, text, mediaURL, user;
  let docs, page, hasNextPage, combinedOrders, status;
  switch (action.type) {
    case USER_ORDER_REQUEST:
      draft.loading = true;
      break;
    case USER_ORDER_SUCCESS:
      combinedOrders = getCombinedOrders(draft.list, [payload]);
      draft.list = combinedOrders;
      draft.loading = false;
      break;
    case USER_ORDER_FAIL:
      draft.loading = false;
      draft.error = payload;
      break;
    case USER_ORDER_LIST_REQUEST:
      draft.loading = true;
      break;
    case USER_ORDER_LIST_SUCCESS:
      docs = payload.docs;
      page = payload.page;
      hasNextPage = payload.hasNextPage;
      combinedOrders = getCombinedOrders(draft.list, docs);
      draft.list = combinedOrders;
      draft.loading = false;
      draft.page['ALL'] = page;
      draft.hasNextPage['ALL'] = hasNextPage;
      break;
    case USER_ORDER_LIST_FAIL:
      draft.loading = false;
      draft.error = payload;
      break;
    case USER_UNREAD_MESSAGES_ORDER_LIST_REQUEST:
      draft.loading = true;
      break;
    case USER_UNREAD_MESSAGES_ORDER_LIST_SUCCESS:
      docs = payload.docs;
      combinedOrders = getCombinedOrders(draft.list, docs);
      draft.list = combinedOrders;
      draft.loading = false;
      break;
    case USER_UNREAD_MESSAGES_ORDER_LIST_FAIL:
      draft.loading = false;
      draft.error = payload;
      break;
    case ORDER_LIST_BY_STATUS_REQUEST:
      draft.loading = true;
      break;
    case ORDER_LIST_BY_STATUS_SUCCESS:
      docs = payload.docs;
      page = payload.page;
      status = payload.status;
      hasNextPage = payload.hasNextPage;
      combinedOrders = getCombinedOrders(draft.list, docs);
      draft.list = combinedOrders;
      draft.loading = false;
      draft.page[status] = page;
      draft.hasNextPage[status] = hasNextPage;
      break;
    case ORDER_LIST_BY_STATUS_FAIL:
      draft.loading = false;
      draft.error = payload;
      break;
    case USER_ORDER_ADD_MESSAGE:
      ({ order, text, mediaURL, user } = payload);
      if (text || mediaURL) {
        i = getIndexOfOrderInList(order, draft.list);
        if (i >= 0) {
          // create message object
          const message = createMessage(payload);
          draft.list[i].messages.push(message);
          draft.loading = false;
        }
      }
      break;
    case ORDER_CONDENSER_STATUS:
      draft.condenser.status = payload;
      break;
    case CHANNEL_USER_ORDER_UPDATED:
      order = payload.order;

      // find order in list
      i = getIndexOfOrderInList(order, draft.list);
      if (i >= 0) {
        draft.list[i] = order;
        draft.loading = false;
      }
      break;
    case ADMIN_UNOPENED_FOR_TOO_LONG_ORDER_LIST_REQUEST:
    case ADMIN_MISSING_MEASUREMENTS_FOR_TOO_LONG_ORDER_LIST_REQUEST:
    case ADMIN_IN_PROGRESS_FOR_TOO_LONG_ORDER_LIST_REQUEST:
      draft.loading = true;
      break;
    case ADMIN_UNOPENED_FOR_TOO_LONG_ORDER_LIST_SUCCESS:
      draft.admin.unopenedTooLong = payload;
      draft.loading = false;
      break;
    case ADMIN_MISSING_MEASUREMENTS_FOR_TOO_LONG_ORDER_LIST_SUCCESS:
      draft.admin.missingMeasurementsTooLong = payload;
      draft.loading = false;
      break;
    case ADMIN_IN_PROGRESS_FOR_TOO_LONG_ORDER_LIST_SUCCESS:
      draft.admin.inProgressTooLong = payload;
      draft.loading = false;
      break;
    case ADMIN_UNOPENED_FOR_TOO_LONG_ORDER_LIST_FAIL:
    case ADMIN_MISSING_MEASUREMENTS_FOR_TOO_LONG_ORDER_LIST_FAIL:
    case ADMIN_IN_PROGRESS_FOR_TOO_LONG_ORDER_LIST_FAIL:
      draft.loading = false;
      draft.error = payload;
      break;
  }
}, initialState);

export default orderReducer;
