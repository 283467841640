import React from 'react';
import { Spinner } from 'react-bootstrap';

import Button from '../Button';
import { CenterContentBox, RightAlignedBox } from '../../../layout';
import StyledDesktopWrapper from '../../styled/show-more-button/StyledDesktopWrapper';

const ShowMoreButtonDesktop = ({ loading, onClick }) => {
  // if we're currently loading data, show a spinner
  if (loading) {
    return (
      <RightAlignedBox>
        <StyledDesktopWrapper>
          <CenterContentBox width='132px'>
            <Spinner animation='border' size='sm' />
          </CenterContentBox>
        </StyledDesktopWrapper>
      </RightAlignedBox>
    );
  }

  // if not, show the button
  return (
    <RightAlignedBox>
      <StyledDesktopWrapper>
        <Button isOutline isTertiary onClick={onClick}>
          Show more
        </Button>
      </StyledDesktopWrapper>
    </RightAlignedBox>
  );
};

export default ShowMoreButtonDesktop;
