import React from 'react';
import StyledBaseText from '../styled/StyledBaseText';
import theme from '../../theme';
const { coconut } = theme.textStyles;

const Coconut = (props) => {
  return (
    <StyledBaseText {...coconut} {...props}>
      {props.children}
    </StyledBaseText>
  );
};

export default Coconut;
