import React from 'react';

import { Button } from '../../../../ui-library/buttons';
import {
  CartIcon,
  CartIconWhite,
  RightArrowIcon,
} from '../../../../ui-library/icons';
import { Space } from '../../../../ui-library/space';
import { URL_FOR_CHECKOUT_FIRST_STEP } from '../../../store/checkout/checkout.constants';
import useModalCheckout from '../../../store/checkout/useModalCheckout';
import useNavigation from '../../../../utils/useNavigation';
import useSidebar from '../useSidebar';

const Toolbar = () => {
  const { Modal, isOpen, hide, handleCheckout } = useModalCheckout();
  const { goToCart } = useNavigation();
  const [, , , hideSidebar] = useSidebar();

  const handleViewShoppingBag = () => {
    hideSidebar();
    goToCart();
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onHide={hide}
        redirectURL={URL_FOR_CHECKOUT_FIRST_STEP}
      />
      <Button block icon={<RightArrowIcon />} onClick={handleCheckout}>
        Checkout
      </Button>
      <Space size={4} />
      <Button
        block
        icon={<CartIcon />}
        iconHover={<CartIconWhite />}
        isOutline
        isTertiary
        onClick={handleViewShoppingBag}
      >
        View Cart
      </Button>
      <Space size={8} />
    </div>
  );
};

export default Toolbar;
