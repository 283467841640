import React from 'react';
import { Button as BootstrapButton } from 'react-bootstrap';
import styled from 'styled-components';

// High level color calculations
const getColorToUse = (props) => {
  if (props.isPrimary) return props.theme.colors.primary.main;
  if (props.isSecondary) return props.theme.colors.secondary.main;
  if (props.isTertiary) return props.theme.colors.grey.dark;
  if (props.isConfirmation) return props.theme.colors.green;
  if (props.isDanger) return props.theme.colors.red;
  if (props.isIcon) return props.theme.colors.transparent;
  if (props.isLink) return props.theme.colors.transparent;
  if (props.isText) return props.theme.colors.transparent;
  return props.theme.colors.blue;
};
const getDarkColorToUse = (props) => {
  if (props.disabled) return 'transparent !important';
  if (props.hoverBackgroundColor) return props.hoverBackgroundColor;
  if (props.isPrimary) return props.theme.colors.primary.dark;
  if (props.isSecondary) return props.theme.colors.secondary.dark;
  if (props.isTertiary) return props.theme.colors.grey.dark;
  if (props.isConfirmation) return props.theme.colors.success.dark;
  if (props.isDanger) return props.theme.colors.error.dark;
  if (props.isIcon) return props.theme.colors.transparent;
  if (props.isLink) return props.theme.colors.transparent;
  if (props.isText) return props.theme.colors.transparent;
  return props.theme.colors.information.dark;
};

// Individual properties
const getBackgroundColor = (props) => {
  if (props.debug) return 'red';
  if (props.disabled) return 'transparent !important';
  if (props.isText) return 'transparent !important';
  if (props.backgroundColor) return props.backgroundColor;
  if (props.isOutline || props.isLink) {
    return 'rgba(0, 0, 0, 0)';
  }
  return getColorToUse(props);
};
const getBorderColor = (props) => {
  if (props.disabled) return 'transparent !important';
  if (props.isText) return 'transparent !important';
  if (props.backgroundColor) return props.backgroundColor;
  if (props.isLink) {
    return 'rgba(0, 0, 0, 0)';
  }
  return getColorToUse(props);
};

const getBorder = (props) => {
  if (props.disabled) return '2px solid transparent !important';
  if (props.border) return props.border;
  if (props.isText) return 0;
  return `2px solid ${getBorderColor(props)}`;
};
const getColor = (props) => {
  if (props.disabled) return `${props.theme.colors.grey.dark} !important`;
  if (props.isText) return `${props.theme.colors.blue} !important`;
  if (props.isOutline) {
    return getColorToUse(props);
  }
  return props.theme.colors.white;
};
const getCursor = (props) => {
  if (props.disabled) return 'not-allowed';
  return 'pointer';
};
const getFontSize = (props) => {
  if (props.isText) return '0.875rem !important';
  switch (props.size) {
    case 'sm':
      return '0.65rem !important;';
    default:
      return '0.875rem !important;';
  }
};
const getHeight = (props) => {
  switch (props.size) {
    case 'xs':
      return '24px !important;';
    case 'sm':
      return '32px !important;';
    case 'md':
      return '40px !important;';
    case 'lg':
      return '64px !important;';
    case 'xl':
      return '68px !important;';
    default:
      return '48px !important;';
  }
};
const getHoverBackgroundColor = (props) => {
  return getDarkColorToUse(props);
};
const getHoverBorder = (props) => {
  if (props.isText) return '2px solid rgba(0, 0, 0, 0)';
  if (!props.isOutline) return '2px solid rgba(0, 0, 0, 0)';
  if (props.isPrimary) {
    return `2px solid ${getDarkColorToUse(props)}`;
  }
  return `2px solid ${getDarkColorToUse(props)}`;
};
const getPadding = (props) => {
  if (props.padding) return props.padding;
  if (props.isIcon) return 0;
  if (props.isText) return 0;
  if (props.size === 'sm') return '5px 16px';
  return '7px 16px';
};

const StyledButton = styled(
  ({
    isConfirmation,
    isDanger,
    isIcon,
    isLink,
    isOutline,
    isPrimary,
    isSecondary,
    isTertiary,
    isText,
    backgroundColor,
    hoverBackgroundColor,
    letterSpacing,
    textDecoration,
    textTransform,
    ...rest
  }) => <BootstrapButton {...rest} />
)`
  background-color: ${(props) => getBackgroundColor(props)};
  border: ${(props) => getBorder(props)};
  border-radius: 8px;
  color: ${(props) => getColor(props)};
  cursor: ${(props) => getCursor(props)};
  font-size: ${(props) => getFontSize(props)};
  font-weight: ${(props) => props.fontWeight || '400'};
  height: ${(props) => getHeight(props)};
  letter-spacing: ${(props) => props.letterSpacing || '0.75px'};
  margin: ${(props) => props.margin || 0};
  padding: ${(props) => getPadding(props)};
  text-decoration: ${(props) => props.textDecoration || 'none'};
  text-transform: ${(props) => props.textTransform || 'uppercase'};

  box-shadow: none !important; /* To remove border around button when you click */

  :active {
    background-color: ${(props) => getHoverBackgroundColor(props)} !important;
    border: ${(props) => getHoverBorder(props)} !important;
  }
  :focus {
    background-color: ${(props) => getHoverBackgroundColor(props)} !important;
    border: ${(props) => getHoverBorder(props)} !important;
  }
  :hover {
    background-color: ${(props) => getHoverBackgroundColor(props)} !important;
    border: ${(props) => getHoverBorder(props)} !important;
  }
  :not:hover {
    border: 2px solid red !important;
  }
`;
export default StyledButton;
