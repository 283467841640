import React from 'react';
import PropTypes from 'prop-types';
import FormTextField from '../../FormTextField';
import FormNumberField from '../../FormNumberField';
import FormCountryField from '../../FormCountryField';
import FormRegionField from '../../FormRegionField';
import { Box, GasBox } from '../../layout';
import { Gap } from '../../space';

/**
 * AddressFields can be used to capture all address related information from the user.
 *
 * If multiple AddressFields need to be used within the same form (on the same page), there will be multiple line1, line2, city, etc - one for each instance. To identify which form is which, we pass in a prefix.
 *
 * Eg: AddressPage has two instances of AddressFields - one for shipping and one for billing. To separate the instances, we pass in the prefixes shipping_ and billing_ . This gives the Field name shipping_line1, billing_line1, etc.
 */
const AddressFields = ({
  title = '',
  prefix = '',
  values,
  disabled = false,
}) => {
  return (
    <div>
      <Box marginBottom='16px'>
        <FormTextField
          name={`${prefix}line1`}
          title='Address'
          disabled={disabled}
        />
      </Box>
      <Box marginBottom='16px'>
        <FormTextField
          name={`${prefix}line2`}
          title='Apartment, Suite, etc'
          disabled={disabled}
        />
      </Box>

      <Box marginBottom='16px'>
        <FormCountryField name={`${prefix}country`} disabled={disabled} />
      </Box>
      <GasBox marginBottom='16px' gap='16px'>
        <FormRegionField
          name={`${prefix}region`}
          country={values[`${prefix}country`]}
          disabled={disabled}
        />
        <FormTextField
          name={`${prefix}city`}
          title='City'
          disabled={disabled}
        />
      </GasBox>

      <GasBox marginBottom='16px'>
        <FormTextField
          name={`${prefix}zipPostalCode`}
          title='Zip / Postal Code'
          disabled={disabled}
        />
        <Gap size={16} />
        <FormNumberField
          name={`${prefix}mobileNumber`}
          decimalScale='0'
          fixedDecimalScale={false}
          prefix={'+'}
          title='Mobile Number'
          disabled={disabled}
        />
      </GasBox>
    </div>
  );
};

AddressFields.propTypes = {
  values: PropTypes.object.isRequired,
  prefix: PropTypes.string,
};

export default AddressFields;
