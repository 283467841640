import React from 'react';
import { Col, Row } from 'react-bootstrap';

import AnimatedEmptyBox from '../ui-library/layout/elements/AnimatedEmptyBox';
import StyledContainer from '../ui-library/styled/StyledContainer';

const StoreEmptyState = () => {
  return (
    <StyledContainer>
      <Row noGutters>
        <Col md={4} sm={12}>
          <AnimatedEmptyBox />
        </Col>
        <Col md={4} sm={12}>
          <AnimatedEmptyBox />
        </Col>
        <Col md={4} sm={12}>
          <AnimatedEmptyBox />
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default StoreEmptyState;
