import React from 'react';
import StyledHugLeft, { StyledColumn } from '../styled/StyledHugLeft';

/**
 * Column stays hugging to the left. Restricts column width to maxWidth irrespective of browser width. Used for the 2nd column in CartPage and CheckoutPage
 * @param {String} maxWidthDesktop Eg '400px' Max Width for this column in Desktop
 * @param {String} maxWidthMobile Eg '400px' Max Width for this column in Mobile
 */
const HugLeft = ({ children, maxWidthDesktop, maxWidthMobile }) => (
  <StyledHugLeft>
    <StyledColumn
      maxWidthDesktop={maxWidthDesktop}
      maxWidthMobile={maxWidthMobile}
    >
      {children}
    </StyledColumn>
  </StyledHugLeft>
);

export default HugLeft;
