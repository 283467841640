import React from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap';

import { Button } from '../../../buttons';
import { ButtonCloseFilledIcon } from '../../../icons';
import { Cabbage, Earth } from '../../../typography';
import { CenterContentBox } from '../../../layout';
import StyledCloseButton from '../styled/StyledCloseButton';
import StyledContent from '../styled/StyledContent';
import StyledHeader from '../styled/StyledHeader';

const Modal = ({
  isOpen,
  onHide,
  title,
  subtitle,
  children,
  styleOverrides = {},
}) => {
  const {
    header: headerStyle,
    title: titleStyle,
    content: contentStyle,
    size,
  } = styleOverrides;
  return (
    <BootstrapModal
      show={isOpen}
      onHide={onHide}
      style={{ borderRadius: '20px', overflow: 'hidden' }}
      size={size || 'md'}
    >
      <BootstrapModal.Body>
        <StyledCloseButton>
          <Button icon={<ButtonCloseFilledIcon />} size='md' onClick={onHide} />
        </StyledCloseButton>
        <StyledHeader style={headerStyle}>
          <CenterContentBox>
            <Cabbage style={titleStyle}>{title}</Cabbage>
          </CenterContentBox>
          <CenterContentBox>
            <Earth>{subtitle}</Earth>
          </CenterContentBox>
        </StyledHeader>

        <StyledContent style={contentStyle}>
          <CenterContentBox>{children}</CenterContentBox>
        </StyledContent>
      </BootstrapModal.Body>
    </BootstrapModal>
  );
};

export default Modal;
