export const settings = {
  activeDir: "ltr",
  activeThemeLayout: "vertical",
  activeTheme: "light",
  activeSidebarType: "full",
  activeLogoBg: "skin6",
  activeNavbarBg: "skin1",
  activeSidebarBg: "skin6",
  activeSidebarPos: "fixed",
  activeHeaderPos: "fixed",
  activeLayout: "full",
};