import React, { useState } from 'react';
import { Lemon } from '../../typography';
import StyledButton from '../styled/StyledButton';
import StyledFlexRow from '../styled/StyledFlexRow';
import StyledIconWrapper from '../styled/StyledIconWrapper';

/**
 * @param {Elements} children
 * @param {Icon Element} icon Icon to use (optional)
 * @param {Icon Element} iconHover Icon to use on hover (optional)
 */
const Button = ({
  children,
  icon,
  iconHover,
  iconOnLeft,
  size,
  padding,
  ...rest
}) => {
  const [isHover, setIsHover] = useState(false);

  const handleMouseOver = () => {
    setIsHover(true);
  };
  const handleMouseOut = () => {
    setIsHover(false);
  };

  // Swap icon on hover, if available
  const getIcon = () => {
    if (isHover && iconHover) return iconHover;
    return icon;
  };

  // No icon? Use a regular button
  if (!icon) {
    return (
      <StyledButton
        children={children}
        size={size}
        padding={padding}
        {...rest}
      />
    );
  }

  // Has icon but no children? It's a button with just an icon (no text - text will be in children)
  if (icon && !children) {
    return (
      <StyledButton isIcon size={size} padding={padding} {...rest}>
        {icon}
      </StyledButton>
    );
  }

  // Wants an icon? Use a flexbox to position icon and text appropriately
  return (
    <Button
      {...rest}
      size={size || 'lg'}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <StyledFlexRow padding={padding}>
        {iconOnLeft ? (
          <>
            <StyledIconWrapper iconOnLeft>{getIcon()}</StyledIconWrapper>
            <Lemon>{children}</Lemon>
          </>
        ) : (
          <>
            <Lemon>{children}</Lemon>
            <StyledIconWrapper>{getIcon()}</StyledIconWrapper>
          </>
        )}
      </StyledFlexRow>
    </Button>
  );
};

export default Button;
