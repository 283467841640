import React from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { AddIcon, SubtractIcon } from '../../../../ui-library/icons';
import { Box } from '../../../../ui-library/layout';
import { Button } from '../../../../ui-library/buttons';
import { Earth, Orange } from '../../../../ui-library/typography';
import {
  decrementQuantityForProductAtIndex,
  decrementQuantityForProductOnServerCartAtIndex,
  incrementQuantityForProductAtIndex,
  incrementQuantityForProductOnServerCartAtIndex,
  updateQuantityForProductAtIndex,
  updateQuantityForProductOnServerCartAtIndex,
} from '../cart.actions';
import { Gap } from '../../../../ui-library/space';
import { trackUpdateQuantityInCart } from '../../../tracking/shop.tracking';

const StyledBox = styled(Box)`
  align-items: center;

  @media (max-width: 441px) {
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 16px;
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;

  background-color: #f1f0e0;
  border: 1px solid #c9c8be;
  border-radius: 4px;

  padding: 0;
  max-height: 26px;
`;
const StyledButtonWrapper = styled.div`
  margin-top: -3px;
  width: 26px;
  text-align: center;
`;

const Quantity = ({ index, item, isEditable }) => {
  const dispatch = useDispatch();
  const handleChange = (value) => {
    dispatch(updateQuantityForProductAtIndex(value, index));
    dispatch(updateQuantityForProductOnServerCartAtIndex(value, index));
    trackUpdateQuantityInCart(value);
  };
  const handleIncrement = () => {
    dispatch(incrementQuantityForProductAtIndex(index));
    dispatch(incrementQuantityForProductOnServerCartAtIndex(index));
  };
  const handleDecrement = () => {
    dispatch(decrementQuantityForProductAtIndex(index));
    dispatch(decrementQuantityForProductOnServerCartAtIndex(index));
  };
  return (
    <StyledBox>
      <Earth lineHeight='15px'>Quantity : </Earth>
      <Gap size={2} />
      {isEditable ? (
        <StyledWrapper>
          <StyledButtonWrapper>
            <Button block icon={<SubtractIcon />} onClick={handleDecrement} />
          </StyledButtonWrapper>
          <NumberFormat
            value={item.quantity}
            isAllowed={(values) => {
              const { floatValue } = values;
              return floatValue > 0;
            }}
            onChange={(e) => {
              handleChange(e.target.value);
            }}
            style={{
              borderColor: 'transparent',
              backgroundColor: '#f1f0e0',
              fontSize: '14px',
              maxWidth: '42px',
              maxHeight: '24px',
              margin: 0,
              textAlign: 'center',
            }}
          />
          <StyledButtonWrapper>
            <Button block icon={<AddIcon />} onClick={handleIncrement} />
          </StyledButtonWrapper>
        </StyledWrapper>
      ) : (
        <Orange>{item.quantity}</Orange>
      )}
    </StyledBox>
  );
};

export default Quantity;
