import React from 'react';

const useSubcomponents = (children) => {
  const left = React.Children.map(children, (child) =>
    child?.type?.displayName === 'Left' ? child : null
  );
  const right = React.Children.map(children, (child) =>
    child?.type?.displayName === 'Right' ? child : null
  );
  return { left, right };
};

export default useSubcomponents;
