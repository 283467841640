import React from 'react';
import { Spinner } from 'react-bootstrap';

import Button from '../Button';
import { Box, CenterContentBox } from '../../../layout';
import StyledMobileWrapper from '../../styled/show-more-button/StyledMobileWrapper';

const ShowMoreButtonMobile = ({ loading, onClick }) => {
  // if we're currently loading data, show a spinner
  if (loading) {
    return (
      <Box>
        <StyledMobileWrapper>
          <CenterContentBox width='100%'>
            <Spinner animation='border' size='sm' />
          </CenterContentBox>
        </StyledMobileWrapper>
      </Box>
    );
  }

  // if not, show the button
  return (
    <Box>
      <StyledMobileWrapper>
        <Button block isOutline isTertiary onClick={onClick}>
          Show more
        </Button>
      </StyledMobileWrapper>
    </Box>
  );
};

export default ShowMoreButtonMobile;
