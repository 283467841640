import React from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

import Measurements from './Measurements';
import UnitOfMeasurement from './UnitOfMeasurement';

const validate = (values) => {
  // This exists for possible future use case where we might allow editing measurements from here
  return true;
};

/**
 * Renders a measurement form.
 *
 * IMPORTANT: Currently, this is a read only form. In spite of that, we have used react final form here for 2 reasons
 * 1. There is a strong possibility we will allow this to be editable for tailors in the future
 * 2. Until then, we can benefit from existing UI components (FormTextField) which is already styled the way we need.
 * @param {Array} measurements
 * @param {String} unitOfMeasurement
 */
const MeasurementsForm = ({ measurements, unitOfMeasurement }) => {
  const initialValues = { measurements, unitOfMeasurement };
  const onSubmit = () => {};
  const handleSubmit = () => {}; // placeholder (See notes above)
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validate}
      mutators={{
        ...arrayMutators,
      }}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          <div className='px-4'>
            <UnitOfMeasurement unitOfMeasurement={unitOfMeasurement} />
            <FieldArray name='measurements' values={values}>
              {({ fields }) => (
                <Measurements fields={fields} measurements={measurements} />
              )}
            </FieldArray>
          </div>
        </form>
      )}
    />
  );
};

export default MeasurementsForm;
