import breakpoints from './breakpoints';
import colors from './colors';
import { fontFamilies, fontSizes, textStyles } from './typography';

const theme = {
  breakpoints,
  colors,
  fontFamilies,
  fontSizes,
  textStyles,
};

export default theme;
