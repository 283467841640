import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { CenterContentBox } from '../../ui-library/layout';
import GoogleLoginButton from '../../ui-library/buttons/elements/GoogleLoginButton';
import LocalAuth from './local-auth/LocalAuth';
import { Modal } from '../../ui-library/molecules/modals';
import {
  trackCloseBtnClick,
  trackGoogleSSOClick,
} from '../tracking/onboarding.tracking';
import { Venus } from '../../ui-library/typography';
import { getHeaderText, shouldShowSocialLogin } from './auth.utils';

const LoginRegisterModal = ({ isOpen, onHide, redirectURL, initialUI }) => {
  const [currentUI, setCurrentUI] = useState(initialUI);
  const handleGoogleLoginClick = () => {
    trackGoogleSSOClick();
  };
  const handleClose = () => {
    trackCloseBtnClick();
    onHide();
  };
  return (
    <Modal
      isOpen={isOpen}
      onHide={handleClose}
      title={getHeaderText(currentUI)}
    >
      <CenterContentBox
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        width='84%'
      >
        {shouldShowSocialLogin(currentUI) && (
          <div>
            <CenterContentBox>
              <GoogleLoginButton
                redirectURL={redirectURL}
                callback={handleGoogleLoginClick}
              />
            </CenterContentBox>
            <CenterContentBox padding='16px 0 16px 0'>
              <Venus>or</Venus>
            </CenterContentBox>
          </div>
        )}
        <LocalAuth
          redirectURL={redirectURL}
          onHide={onHide}
          initialUI={initialUI}
          currentUICallback={setCurrentUI}
        />
      </CenterContentBox>
    </Modal>
  );
};

LoginRegisterModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default LoginRegisterModal;
