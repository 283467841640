import React, { useEffect, useRef, useState } from 'react';
import { Route } from 'react-router-dom';

import useNavigation from '../utils/useNavigation';
import Loader from '../ui-library/Loader';
import { getPathname } from '../utils/url';
import useAuth from '../modules/auth/useAuth';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { goToLoginPage } = useNavigation();
  const { user } = useAuth();
  const LOGIN_STATUS = {
    notLoggedIn: 0,
    loggedIn: 1,
  };
  const [loginStatus, setLoginStatus] = useState(
    user ? LOGIN_STATUS.loggedIn : LOGIN_STATUS.notLoggedIn
  );

  const pathname = getPathname(document.location.href);

  // We need to access the user inside setTimeout. Due to closure issues, we won't get the latest value of the 'user' variable. Using useRef helps us get the 'current' value of user
  // https://github.com/facebook/react/issues/14010
  const userRef = useRef(user);
  userRef.current = user;

  /**
   * We wait for 3 seconds to see if the redux store has the authenticated user. If the user is directly coming to a private URL, this should give enough time for the data to be retrieved from the DB and filled in the store.
   *
   * However, if after 3 seconds we still do not have the user, we redirect them to the login page. While doing this, we get the pathname current URL they are at so that after authentication, they can be redirected to the destination they wanted.
   *
   * Eg: If the user is NOT logged in but directly tries to access askokey.com/orders/e83udko37, after 3 seconds we will redirect them to /login and pass the pathname as the redirect parameter, ie /login?redirect=/orders/e83udko37
   */
  useEffect(() => {
    setTimeout(() => {
      if (!userRef.current) {
        goToLoginPage(pathname);
      }
    }, 3000);

    if (user) {
      setLoginStatus(LOGIN_STATUS.loggedIn);
    }
  });

  if (loginStatus === LOGIN_STATUS.loggedIn) {
    return (
      <Route
        key={document.location.href}
        {...rest}
        component={(props) => (
          <div>
            <Component {...props} />
          </div>
        )}
      />
    );
  }

  return <Loader />;
};

export default PrivateRoute;
