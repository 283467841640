import React from 'react';

import Modal from '../Modal';
import useOptions from './useOptions';
import ProductOptions from './ProductOptions';

const OptionsModal = ({ isOpen, onHide, options }) => {
  const { subtitle } = useOptions(options);
  return (
    <Modal isOpen={isOpen} onHide={onHide} title='Options' subtitle={subtitle}>
      <div className='full-width' style={{ padding: '8px 32px' }}>
        <ProductOptions options={options} />
      </div>
    </Modal>
  );
};

export default OptionsModal;
