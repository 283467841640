import React from 'react';
import { Form } from 'react-final-form';
import { isEmpty } from 'validate.js';

import { Box } from '../../../ui-library/layout';
import FormEmailField from '../../../ui-library/FormEmailField';
import FormPasswordField from '../../../ui-library/FormPasswordField';
import { isValidEmail } from '../../../utils/validateEmails';
import { LoadingButton, TextButton } from '../../../ui-library/buttons';
import {
  trackForgotPasswordLinkClick,
  trackNeedAccountClick,
} from '../../tracking/signin.tracking';
import { Venus } from '../../../ui-library/typography';

const validate = (values) => {
  const errors = {};
  if (!isValidEmail(values.email)) {
    errors.email = 'Please enter a valid email';
  }
  if (isEmpty(values.plainTextPassword)) {
    errors.plainTextPassword = 'Password is required';
  }
  return errors;
};

const LoginForm = ({
  onWantToResetPassword,
  onWantToSignUp,
  onSubmit,
  initialValues,
  isLoggingIn,
}) => {
  const handleWantToResetPassword = () => {
    trackForgotPasswordLinkClick();
    onWantToResetPassword();
  };
  const handleWantToSignUp = () => {
    trackNeedAccountClick();
    onWantToSignUp();
  };

  return (
    <div>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <FormEmailField name='email' title='Email' />
            <FormPasswordField name='plainTextPassword' title='Password' />
            <Box justifyContent='flex-end' marginBottom='32px'>
              <TextButton onClick={handleWantToResetPassword}>
                Forgot Password?
              </TextButton>
            </Box>
            <LoadingButton
              block
              isOutline
              type='submit'
              disabled={submitting || pristine}
              size='md'
              isLoading={isLoggingIn}
            >
              Login
            </LoadingButton>
          </form>
        )}
      />
      <Box alignItems='center' justifyContent='center' marginTop='8px'>
        <Venus>Need an account?&nbsp;</Venus>{' '}
        <TextButton onClick={handleWantToSignUp}>Create one</TextButton>
      </Box>
    </div>
  );
};

export default LoginForm;
