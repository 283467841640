const colors = {
  primary: {
    light: '#FCC545',
    main: '#E6AF2E',
    dark: '#B08217',
  },
  secondary: {
    light50: '#BFBEAF',
    light: '#636156',
    main: '#3F3C30',
    dark: '#1E1C11',
  },
  alert: {
    light: '#FF9229',
    main: '#EF6A00',
    dark: '#C76100',
  },
  error: {
    light: '#E21D20',
    main: '#C3181A',
    dark: '#A32216',
  },
  information: {
    light: '#2372D8',
    main: '#1761C0',
    dark: '#1B4BAF',
  },
  success: {
    light50: '#BAE0BD',
    light: '#51C07A',
    main: '#3B9B5E',
    dark: '#238D53',
  },
  text: {
    light25: '#FBFAEF',
    light50: '#F8F7EA',
    light: '#F1F0E0',
    main: '#C9C8BE',
    dark25: '#C4C3BF',
    dark50: '#AAA897',
    dark: '#635F4D',
  },
  warning: {
    light: '#FFEAB6',
    main: '#FCDE91',
    dark: '#EDBF49',
  },
};
colors.black = '#28261B';
colors.blue = colors.information.main;
colors.green = colors.success.main;
colors.grey = colors.text;
colors.orange = colors.alert.main;
colors.red = colors.error.main;
colors.transparent = 'rgba(0,0,0,0)';
colors.white = '#FFFEF4';
colors.yellow = colors.warning.main;

export default colors;
