import React from 'react';

import CartPreview from './cart-preview/CartPreview';
import EmptyCart from '../../store/cart/EmptyCart';

import useCart from '../../store/cart/useCart';
import useNavigation from '../../../utils/useNavigation';
import useSidebar from './useSidebar';

const SidebarContent = () => {
  const { hasItems } = useCart();
  const [, , , hideSidebar] = useSidebar();
  const { goToStore } = useNavigation();

  const handleStartShopping = () => {
    hideSidebar();
    goToStore();
  };

  return (
    <>
      {hasItems ? (
        <CartPreview />
      ) : (
        <EmptyCart onStartShopping={handleStartShopping} />
      )}
    </>
  );
};

export default SidebarContent;
