// Fabric
export const PC_SET_DEFAULT_FABRIC = 'store/PC_SET_DEFAULT_FABRIC';
export const PC_SET_USER_CHOICE_FABRIC = 'store/PC_SET_USER_CHOICE_FABRIC';

// Measurements
export const PC_SET_MEASUREMENTS = 'store/PC_SET_MEASUREMENTS';
export const PC_SET_UNIT_OF_MEASUREMENT = 'store/PC_SET_UNIT_OF_MEASUREMENT';

// Options
export const PC_SET_OPTION = 'PC_SET_OPTION';
export const PC_CLEAR_OPTIONS = 'PC_CLEAR_OPTIONS';

// Trouser Options
export const PC_OPTIONS_KEY_TROUSER_CUFFS = 'trouserCuffs';
export const PC_DEFAULT_TROUSER_CUFFS = true;

// Jacket Options
export const PC_OPTIONS_KEY_NUMBER_OF_JACKET_BUTTONS = 'coatButtons';
export const PC_OPTIONS_KEY_JACKET_POCKET_STYLE = 'pocketStyle';
export const PC_DEFAULT_NUMBER_OF_JACKET_BUTTONS = 3;

// DB Jacket Options
export const PC_OPTIONS_KEY_DB_JACKET_BUTTONS = 'dbCoatButtons';
export const PC_OPTIONS_KEY_DB_JACKET_POCKET_STYLE = 'dbPocketStyle';
export const PC_OPTIONS_KEY_DB_JACKET_NUMBER_OF_CUFF_BUTTONS = 'dbCuffButtons';

// Office Suit Options
export const PC_OPTIONS_KEY_SB_OFFICE_SUIT_NUMBER_OF_CUFF_BUTTONS =
  'osCuffButtons';
export const PC_OPTIONS_KEY_SB_OFFICE_SUIT_POCKET_STYLE = 'osPocketStyle';
export const PC_OPTIONS_KEY_SB_OFFICE_SUIT_TROUSER_CUFFS = 'osTrouserCuffs';

// Three Roll Two Jacket Options
export const PC_OPTIONS_KEY_3R2_NUMBER_OF_CUFF_BUTTONS = 'threeRollTwoCuffButtons';
export const PC_OPTIONS_KEY_3R2_POCKET_STYLE = 'threeRollTwoPocketStyle';

export const PC_OPTIONS_KEY_DB_OFFICE_SUIT_NUMBER_OF_CUFF_BUTTONS =
  'dbOSCuffButtons';
export const PC_OPTIONS_KEY_DB_OFFICE_SUIT_JACKET_BUTTONS = 'dbOSCoatButtons';
export const PC_OPTIONS_KEY_DB_OFFICE_SUIT_TROUSER_CUFFS = 'dbOSTrouserCuffs';

export const PC_JACKET_OPTIONS___POCKET_STYLE___SLANTED = 'Slanted';
export const PC_JACKET_OPTIONS___POCKET_STYLE___STRAIGHT = 'Straight';
export const PC_JACKET_OPTIONS___POCKET_STYLE___PATCH_NO_FLAP =
  'Patch, No Flap';
export const PC_JACKET_OPTIONS___POCKET_STYLE___PATCH_WITH_FLAP =
  'Patch with Flap';
export const PC_JACKET_OPTIONS___POCKET_STYLE___STRAIGHT_WITH_TICKET =
  'Straight w/ Ticket';
export const PC_JACKET_OPTIONS___POCKET_STYLE___SLANTED_WITH_TICKET =
  'Slanted w/ Ticket';
export const PC_DEFAULT_JACKET_POCKET_STYLE =
  PC_JACKET_OPTIONS___POCKET_STYLE___SLANTED;

// Default DB Jacket Options
export const PC_DEFAULT_DB_JACKET_BUTTONS = '6x2';
export const PC_DEFAULT_DB_JACKET_POCKET_STYLE =
  PC_JACKET_OPTIONS___POCKET_STYLE___STRAIGHT;
export const PC_DEFAULT_DB_JACKET_NUMBER_OF_CUFF_BUTTONS = 4;

// Default Office Suit Options
export const PC_DEFAULT_SB_OFFICE_SUIT_NUMBER_OF_CUFF_BUTTONS = 4;
export const PC_DEFAULT_SB_OFFICE_SUIT_POCKET_STYLE =
  PC_JACKET_OPTIONS___POCKET_STYLE___STRAIGHT;
export const PC_DEFAULT_SB_OFFICE_SUIT_TROUSER_CUFFS = true;

// Default 3 roll 2 Jacket Options
export const PC_DEFAULT_3R2_NUMBER_OF_CUFF_BUTTONS = 4;
export const PC_DEFAULT_3R2_POCKET_STYLE = PC_JACKET_OPTIONS___POCKET_STYLE___STRAIGHT;

export const PC_DEFAULT_DB_OFFICE_SUIT_NUMBER_OF_CUFF_BUTTONS = 4;
export const PC_DEFAULT_DB_OFFICE_SUIT_JACKET_BUTTONS = '6x2';
export const PC_DEFAULT_DB_OFFICE_SUIT_TROUSER_CUFFS = true;

// Shirt Options
export const PC_OPTIONS_KEY_SHIRT_CUFF = 'shirtCuff'
export const PC_OPTIONS_KEY_SHIRT_CHEST_POCKET = 'shirtChestPocket';

export const PC_SHIRT_OPTIONS___FRENCH = 'French';
export const PC_SHIRT_OPTIONS___BARREL = 'Barrel';
export const PC_SHIRT_OPTIONS___HAS_CHEST_POCKET = 'Yes';
export const PC_SHIRT_OPTIONS___NO_CHEST_POCKET = 'No';

// Default Shirt Options
export const PC_DEFAULT_SHIRT_CUFF = PC_SHIRT_OPTIONS___BARREL;
export const PC_DEFAULT_SHIRT_CHEST_POCKET = PC_SHIRT_OPTIONS___NO_CHEST_POCKET;

// Pattern
export const PC_SET_PATTERN_PRICE = 'store/PC_SET_PATTERN_PRICE';
export const PC_DEFAULT_PATTERN_PRICE = 250; // price for first time
export const PC_REPEAT_PATTERN_PRICE = 0; // price for all future orders

// Photos
export const PC_SET_PHOTO = 'store/PC_SET_PHOTO';
export const PC_SET_ALL_PHOTOS = 'store/PC_SET_ALL_PHOTOS';
export const PC_CLEAR_PHOTOS = 'store/PC_CLEAR_PHOTOS';

// General
export const PC_CLEAR_CUSTOMIZATION = 'store/PC_CLEAR_CUSTOMIZATION';

// String
export const PC_UNIT_CM = 'cm';
export const PC_UNIT_INCHES = 'inches';

// Order flow tabs
export const PC_TAB_SELECT_OPTIONS = 'Select Options';
export const PC_TAB_SELECT_FABRIC = 'Select Fabric';
export const PC_TAB_MEASUREMENTS = 'Measurements';
export const PC_TAB_POSTURE_CHECK = 'Posture Check';
export const PC_TAB_REVIEW_ITEM = 'Review';

export const PC_TAB_STATUS_COMPLETED = 0;
export const PC_TAB_STATUS_AWAITING = 1;
export const PC_TAB_STATUS_SELECTED = 2;
export const PC_TAB_STATUS_SKIPPED = 3;