import styled from 'styled-components';
import { Box } from '..';

export const StyledColumn = styled.div`
  max-width: ${(props) => props.maxWidthDesktop || '590px'};
  width: 100%;

  @media (max-width: 768px) {
    max-width: ${(props) => props.maxWidthMobile || '648px'};
  }
`;
const StyledHugRight = styled(Box)`
  justify-content: flex-end;
  margin: 64px 32px 0 32px;
`;

export default StyledHugRight;
