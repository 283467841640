import { useHistory } from 'react-router-dom';
import { isEmpty } from 'validate.js';

import {
  URL_CART,
  URL_CUSTOMER_DASHBOARD,
  URL_GENIE_PUBLIC,
  URL_HOME,
  URL_LOGIN_PAGE,
  URL_PAYMENT,
  URL_STORE,
} from './constants';
import { URL_FOR_CHECKOUT_FIRST_STEP } from '../modules/store/checkout/checkout.constants';

const getReloadHomepageURL = () => {
  let url = 'https://askokey.com';
  if (window.location.hostname.includes('localhost')) {
    url = 'http://localhost:3000';
  }
  if (window.location.hostname.includes('dev')) {
    url = 'https://askokey-dev2.herokuapp.com';
  }
  return url;
};

const useNavigation = () => {
  const history = useHistory();

  const goToCart = () => {
    history.push(URL_CART);
  };

  const goToCheckout = () => {
    history.push(URL_FOR_CHECKOUT_FIRST_STEP);
  };

  const goToCustomerDashboard = () => {
    history.push(URL_CUSTOMER_DASHBOARD);
  };

  // EDGE CASE HANDLER. Refer to comments of goToHomeWithReload
  const goToCustomerDashboardWithReload = () => {
    const url = getReloadHomepageURL() + URL_CUSTOMER_DASHBOARD;
    window.open(url, '_self');
  };

  const goToEditCartItemAtIndex = (index) => {
    history.push(`${URL_CART}/edit/${index}`);
  };

  const goToGeniePublic = () => {
    history.push(URL_GENIE_PUBLIC);
  };

  const goToHome = () => {
    history.push(URL_HOME);
  };

  /**
   * EDGE CASE HANDLER
   * Called by OrderConfirmationPage. This is needed for the following scenario:
   * - New user is going through checkout
   * - Signs up
   * - Enters shipping and billing address
   * - Completes purchase
   * - Clicks 'Back to AskOkey'
   *
   * Now, user clicks dropdown and goes to profile > shipping address
   *
   * The address will not be displayed because it is being pulled from state.auth which doesn't have this information yet. It will only have it on next login.
   *
   * So, we fake a reload by using http navigation rather than react router. This will reload auth
   */
  const goToHomeWithReload = () => {
    const url = getReloadHomepageURL();
    window.open(url, '_self');
  };

  const goToLoginPage = (redirectURL) => {
    if (isEmpty(redirectURL)) {
      history.push(URL_LOGIN_PAGE);
    } else {
      history.push(`${URL_LOGIN_PAGE}?redirect=${redirectURL}`);
    }
  };

  const goToMyMeasurementsTab = (tabKey) => {
    history.push(`/my-measurements/${tabKey}`);
  };

  const goToPayment = () => {
    history.push(URL_PAYMENT);
  };

  const goToProduct = (categorySlug, productSlug) => {
    history.push(`${URL_STORE}/${categorySlug}/${productSlug}`);
  };

  const goToProductCustomization = (categorySlug, productSlug, product) => {
    history.push({
      pathname: `${URL_STORE}/${categorySlug}/${productSlug}/customize`,
      product,
    });
  };

  const goToProductPageByCancelingCustomization = () => {
    const productURL = history.location.pathname.replace('customize', '');
    history.push(productURL);
  };

  const goToProfileTab = (tabKey) => {
    history.push(`/profile/${tabKey}`);
  };

  const goToStore = () => {
    history.push(URL_STORE);
  };

  const goToAddWardrobeItem = () => {
    history.push('/wardrobe/add');
  };

  const goToThankYouForPurchasePage = (cart, order) => {
    history.push({
      pathname: '/checkout/thank-you',
      order,
      cart,
    });
  };

  const goToURL = (url) => {
    history.push(url);
  };

  const goToWardrobe = () => {
    history.push('/wardrobe');
  };

  return {
    goToCart,
    goToCheckout,
    goToCustomerDashboard,
    goToCustomerDashboardWithReload,
    goToEditCartItemAtIndex,
    goToGeniePublic,
    goToHome,
    goToHomeWithReload,
    goToLoginPage,
    goToMyMeasurementsTab,
    goToPayment,
    goToProduct,
    goToProductCustomization,
    goToProductPageByCancelingCustomization,
    goToProfileTab,
    goToStore,
    goToAddWardrobeItem,
    goToThankYouForPurchasePage,
    goToURL,
    goToWardrobe,
  };
};

export default useNavigation;
