import React from 'react';
import styled from 'styled-components';
import { getCSN } from '../utils/numbers';

const StyledWholeNumber = styled.span`
  color: ${(props) =>
    props.variant === 'primary'
      ? props.theme.colors.primary.main
      : props.theme.colors.secondary.main};
  font-family: Roboto;
  font-weight: 700;
  font-size: ${(props) => (props.size === 'sm' ? '22px' : '44px')};
  line-height: 42px;
  letter-spacing: 0.5px;
`;
const StyledFraction = styled.span`
  color: ${(props) =>
    props.variant === 'primary'
      ? props.theme.colors.primary.main
      : props.theme.colors.secondary.main};
  font-family: Roboto;
  font-size: ${(props) => (props.size === 'sm' ? '10px' : '14px')};
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.5px;
  margin-left: 4px;
  text-align: center;
  opacity: 0.5;
`;
const StyledWrapper = styled.div`
  display: flex;
`;

const ProductPrice = ({ price, size, variant }) => {
  return (
    <StyledWrapper>
      <StyledWholeNumber size={size} variant={variant}>
        $ {getCSN(price)}
      </StyledWholeNumber>
      <StyledFraction variant={variant}>.00</StyledFraction>
    </StyledWrapper>
  );
};

export default ProductPrice;
