import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { RegionDropdown } from 'react-country-region-selector';

const propTypes = {
  name: PropTypes.string.isRequired,
};

const FormRegionField = ({ name, country, disabled }) => (
  <Field
    name={name}
    subscribe={{ touched: true, error: true }}
    render={({ input, meta }) => {
      const getClassName = () => {
        // If there is an error, make border and arrow red
        if (meta.error && meta.touched) {
          return 'country-region-select-error';
        }
        // If control is disabled
        if (disabled) {
          return 'country-region-select-disabled';
        }
        // If user has chosen a value, change the font style
        if (input.value !== '') {
          return 'country-region-select-value-exists';
        }
        // Default grey style (placeholder style)
        return 'country-region-select';
      };

      // Return RegionDropdown
      // blacklist={{ US: ['AA', 'AE', 'AP'] }} : This removes Armed Forces regions in US. These are not standard regions and was causing 2 bugs
      // https://app.asana.com/0/1201143935906321/1202171793840299/f
      // https://app.asana.com/0/1201143935906321/1201828360129658/f
      return (
        <div>
          <RegionDropdown
            country={country}
            value={input.value}
            onBlur={input.onBlur}
            onFocus={input.onFocus}
            onChange={(value) => input.onChange(value)}
            className={getClassName()}
            disabled={disabled}
            blacklist={{ US: ['AA', 'AE', 'AP'] }}
          />
        </div>
      );
    }}
  />
);

FormRegionField.propTypes = propTypes;

export default FormRegionField;
