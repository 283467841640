import React from 'react';
import { Form } from 'react-final-form';
import { isEmpty } from 'validate.js';

import { Button, LoadingButton, TextButton } from '../../../ui-library/buttons';
import FormEmailField from '../../../ui-library/FormEmailField';
import FormPasswordField from '../../../ui-library/FormPasswordField';
import FormTextField from '../../../ui-library/FormTextField';
import { Box } from '../../../ui-library/layout';
import { Space } from '../../../ui-library/space';
import { Venus } from '../../../ui-library/typography';
import { isValidEmail } from '../../../utils/validateEmails';

const validate = (values) => {
  const errors = {};
  if (isEmpty(values.firstName)) {
    errors.firstName = 'Required';
  }
  if (isEmpty(values.lastName)) {
    errors.lastName = 'Required';
  }
  if (isEmpty(values.email) || !isValidEmail(values.email)) {
    errors.email = 'Required';
  }
  if (isEmpty(values.plainTextPassword)) {
    errors.plainTextPassword = 'Required';
  }
  if (values.plainTextPassword !== values.confirmPassword) {
    errors.confirmPassword = 'Passwords do not match';
  }
  return errors;
};

const SignupForm = ({
  onWantToLogin,
  onSubmit,
  initialValues,
  isSigningUp,
}) => {
  return (
    <div>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <FormTextField name='firstName' title='First Name' />
            <Space size={4} />
            <FormTextField name='lastName' title='Last name' />
            <Space size={4} />
            <FormEmailField name='email' title='Email' />
            <FormPasswordField name='plainTextPassword' title='Password' />
            <FormPasswordField
              name='confirmPassword'
              title='Confirm Password'
            />
            <LoadingButton
              block
              isOutline
              type='submit'
              disabled={submitting || pristine}
              size='md'
              isLoading={isSigningUp}
            >
              SignUp
            </LoadingButton>
          </form>
        )}
      />
      <Box alignItems='center' justifyContent='center' marginTop='8px'>
        <Venus>Already have an account?&nbsp;</Venus>{' '}
        <TextButton onClick={onWantToLogin}>Login</TextButton>
      </Box>
    </div>
  );
};

export default SignupForm;
