import React from 'react';

import { Button } from '../../../../ui-library/buttons';
import { ButtonCloseIcon } from '../../../../ui-library/icons';
import { Box } from '../../../../ui-library/layout';
import { Cabbage } from '../../../../ui-library/typography';
import CartList from '../../../store/cart/CartList';
import useCart from '../../../store/cart/useCart';
import useSidebar from '../useSidebar';

const Header = () => {
  const { cart } = useCart();
  const [, , , hideSidebar] = useSidebar();

  return (
    <div className='full-width'>
      <Box justifyContent='space-between' alignItems='baseline'>
        <Cabbage>Your items</Cabbage>
        <Button icon={<ButtonCloseIcon />} onClick={hideSidebar} />
      </Box>
      <CartList cart={cart} />
    </div>
  );
};

export default Header;
