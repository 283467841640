export const validate = (values, prefix) => {
  const errors = {};
  if (!values[`${prefix}line1`] || values[`${prefix}line1`].length < 1) {
    errors[`${prefix}line1`] = 'Required';
  }
  if (!values[`${prefix}city`] || values[`${prefix}city`].length < 1) {
    errors[`${prefix}city`] = 'Required';
  }
  if (!values[`${prefix}country`] || values[`${prefix}country`].length < 1) {
    errors[`${prefix}country`] = 'Required';
  }
  if (!values[`${prefix}region`] || values[`${prefix}region`].length < 1) {
    errors[`${prefix}region`] = 'Required';
  }
  if (
    !values[`${prefix}zipPostalCode`] ||
    values[`${prefix}zipPostalCode`].length < 1
  ) {
    errors[`${prefix}zipPostalCode`] = 'Required';
  }
  if (
    !values[`${prefix}mobileNumber`] ||
    values[`${prefix}mobileNumber`].length < 1
  ) {
    errors[`${prefix}mobileNumber`] = 'Required';
  }

  return errors;
};
