import styled from 'styled-components';

const StyledPageTopper = styled.div`
  width: 100%;
  height: 5px;

  background-color: ${(props) => props.theme.colors.primary.main};
`;

export default StyledPageTopper;
