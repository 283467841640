import { useSelector } from 'react-redux';

const useCart = () => {
  const cart = useSelector((state) => state.cart);

  const countOfItems = cart ? cart.length : 0;

  const getItemAtIndex = (index) => {
    if (index > -1 && index < cart.length) {
      return cart[index];
    }
    return null;
  };

  const hasItems = cart && cart.length > 0;

  return {
    cart,
    countOfItems,
    getItemAtIndex,
    hasItems,
  };
};

export default useCart;
