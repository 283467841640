import styled from 'styled-components';

/* 
Use this to position multiple divs inside a flex box based on CONTAINER width rather than SCREEN width. It allows child divs to be in a row when space is available and switch to column when its container isn't wide enough.

When we use @media queries, it will switch the CSS depending on the screen width. However, if we need to modify the layout based on the container width, we can't use @media. (Eg, CartListItem may be placed on the right side bar on checkout. Here, they have less space and should switch to the narrow layout, but @media will still be measuring screen width. So it won't switch.

Using this class will allow the switch to happen.

https://css-tricks.com/putting-the-flexbox-albatross-to-real-use/ 

NOTE: The intention of this class is similar to GasBox. However for some reason, they behave differently. This works well for CartListItem in /checkout/address. GasBox works correctly for AddressFields in /checkout/address. Ideally, they should be merged into one class
*/

const StyledAlbatross = styled.div`
  display: flex;
  flex-basis: calc(var(--multiplier) * 999);
  flex-grow: 1;
  flex-wrap: wrap;

  align-items: center;
  gap: ${(props) => props.gap || '8px'};

  min-width: 33%;
  max-width: 100%;
  --margin: 1rem;
  --multiplier: calc(40rem - 100%);
`;

export default StyledAlbatross;
