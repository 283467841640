import React from 'react';

import theme from './theme';
import { Venus } from './typography';

const FormErrorText = ({ showError, text }) => {
  return (
    <div style={{ minHeight: '16px' }}>
      {showError ? (
        <div style={{ marginBottom: '16px' }}>
          {' '}
          <Venus color={theme.colors.error.main}>{text}</Venus>{' '}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default FormErrorText;
