import React from 'react';
import { useLocation } from 'react-router';

import EmptyState from '../modules/store/EmptyState';
import Loader from '../ui-library/Loader';
import StoreEmptyState from './StoreEmptyState';
import { URL_STORE } from '../utils/constants';

const SuspenseFallback = () => {
  const location = useLocation();
  // Handles product fallback: /store/trousers/flat-front
  if (location.pathname.startsWith(`${URL_STORE}/`)) {
    return <EmptyState />;
  }

  // Handles storefront fallback
  if (location.pathname.startsWith(`${URL_STORE}`)) {
    return <StoreEmptyState />;
  }

  // Default loader
  return <Loader />;
};

export default SuspenseFallback;
