import React from 'react';
import { Box } from '../../../ui-library/layout';
import { Earth, Orange } from '../../../ui-library/typography';

const PriceRow = ({ title, value }) => {
  return (
    <Box justifyContent='space-between' alignItems='center' marginBottom='8px'>
      <Orange>{title}</Orange>
      <Earth>${value}</Earth>
    </Box>
  );
};

export default PriceRow;
