import React from 'react';
import StyledGasBox from '../styled/StyledGasBox';

/**
 * GasBox because it takes as much space as available
 * If the screen is wide, it will put all children in a single row
 * If the screen is narrow, it will put the children in a column
 *
 * If this doesn't serve the purpose, try Albatross
 */
const GasBox = (props) => {
  return <StyledGasBox {...props} />;
};

export default GasBox;
