import produce from 'immer';
import { isArray, isEmpty } from 'validate.js';
import { getIndexOfObjectInListByKey } from '../../utils/array';
import {
  ORDER_OVERVIEW_LIST_FAIL,
  ORDER_OVERVIEW_LIST_REQUEST,
  ORDER_OVERVIEW_LIST_SUCCESS,
} from './order-overview.constants';

/**
 * SHAPE:
 * list: array of orders
 * page: current page number (for pagination results)
 * hasNextPage: Same logic as page, but to store hasNextPage from pagination results
 */
const initialState = {
  list: [],
  page: -1,
  hasNextPage: false,
};

export const getIndexOfOrderInList = (order, list) =>
  getIndexOfObjectInListByKey(order, list, 'orderNumber');

export const isOrderInList = (order, list) => {
  const index = getIndexOfOrderInList(order, list);

  return index > -1;
};
export const getCombinedOrders = (currentArray, updatedArray) => {
  if (isEmpty(updatedArray) || !isArray(updatedArray)) return currentArray;

  const result = currentArray.concat([]); // make a copy

  updatedArray.forEach((ele) => {
    if (isOrderInList(ele, result)) {
      const index = getIndexOfOrderInList(ele, result);
      result[index] = ele;
    } else {
      result.push(ele);
    }
  });
  return result;
};

const orderOverviewReducer = produce((draft, action) => {
  const { payload } = action;
  let docs, combinedOrders;
  switch (action.type) {
    case ORDER_OVERVIEW_LIST_REQUEST:
      draft.loading = true;
      break;
    case ORDER_OVERVIEW_LIST_SUCCESS:
      docs = payload.docs;
      combinedOrders = getCombinedOrders(draft.list, docs);
      draft.list = combinedOrders;
      draft.loading = false;
      draft.page = payload.page;
      draft.hasNextPage = payload.hasNextPage;
      break;
    case ORDER_OVERVIEW_LIST_FAIL:
      draft.loading = false;
      draft.error = payload;
      break;
  }
}, initialState);

export default orderOverviewReducer;
