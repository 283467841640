import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  COUPON_FIXED_AMOUNT,
  COUPON_PERCENTAGE,
} from '../../../admin/coupon/coupon.constants';

const useCoupon = (cartAmount) => {
  const coupon = useSelector((state) => state.coupon);
  const [discountAmount, setDiscountAmount] = useState(0);

  useEffect(() => {
    let newDiscountAmount = 0;
    if (coupon.valueType === COUPON_FIXED_AMOUNT)
      newDiscountAmount = coupon?.value;
    if (coupon.valueType === COUPON_PERCENTAGE)
      newDiscountAmount = (cartAmount?.grandTotal * coupon?.value) / 100;

    setDiscountAmount(newDiscountAmount);
  }, [cartAmount, coupon?.code]);

  return { coupon, discountAmount };
};

export default useCoupon;
