import styled, { keyframes } from 'styled-components';

const moveGradientBG = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;
const StyledAnimatedEmptyBox = styled.div`
  display: flex;

  background: linear-gradient(90deg, #fffef4, #f1f0e0, #c9c8be);
  background-size: 400%, 400%;
  border-radius: 8px;

  animation: ${moveGradientBG} 5s ease infinite;

  width: 100%;
  height: ${(props) => props.height || '100px'};
`;
export default StyledAnimatedEmptyBox;
