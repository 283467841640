import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import styled from 'styled-components';

import logo from './Logo.svg';
import whiteLogo from './LogoWhite.svg';

// Standard Logo
const Logo = () => <img src={logo} alt='AskOkey logo' />;

// White Logo
export const LogoWhite = () => <img src={whiteLogo} alt='AskOkey logo' />;

// Logo with y-margins
const StyledDiv = styled.div`
  margin: 32px 0;
  cursor: pointer;
`;
export const LogoSpaced = () => (
  <LinkContainer to='/' exact>
    <StyledDiv>
      <Logo />
    </StyledDiv>
  </LinkContainer>
);

export default Logo;
