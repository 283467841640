import React, { useEffect } from 'react';
import { Router, Switch } from 'react-router-dom';

import CookieConsent from 'react-cookie-consent';
import Pusher from 'pusher-js';
import { useDispatch, useSelector } from 'react-redux';
import { createBrowserHistory } from 'history';
import { fetchUser } from '../modules/auth/auth.actions';

import Sidebar from '../modules/ui/sidebar/Sidebar';
import BrowserRoutes from './BrowserRoutes';

import FullLayout from '../modules/dashboard/layouts/FullLayout';
import { identify } from '../modules/tracking/tracking';
import PrivateRoute from '../routers/PrivateRoute';
import { ASKOKEY_PREFIX, ORDER_UPDATED } from './app.constants';
import { getOrderByOrderNumber } from '../modules/order/order.actions';
import SalesIQ from './SalesIQ';
import useAnalytics from './useAnalytics';

export const history = createBrowserHistory();

// Setup Pusher
const pusher = new Pusher(process.env.REACT_APP_PUSHER_CLIENT_KEY, {
  cluster: 'mt1',
  forceTLS: true,
});

let channel = null;

const App = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  useAnalytics(history);

  // Subscribe to Pusher events
  const subscribeToEvents = () => {
    const channelName = ASKOKEY_PREFIX + auth._id;
    channel = pusher.subscribe(channelName);
    channel.bind(ORDER_UPDATED, (data) => {
      dispatch(getOrderByOrderNumber(data.orderNumber));
    });
  };

  // Register user in tracker (Mixpanel)
  const identifyUserInTracker = () => {
    identify(auth._id, {
      firstName: auth.firstName,
      lastName: auth.lastName,
      email: auth.email,
    });
  };

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  // Subscribe to events only after auth is available
  // The user's id is part of the channel we will subscribe to
  useEffect(() => {
    if (auth) {
      subscribeToEvents();
      identifyUserInTracker();
    }
  }, [auth]);

  return (
    <Router history={history}>
      <div>
        <SalesIQ />
        <CookieConsent
          style={{ background: '#1E1C11', fontSize: '14px' }}
          buttonStyle={{
            backgroundColor: '#1761C0',
            color: '#FFFEF4',
            fontSize: '14px',
            borderRadius: '4px',
          }}
        >
          We use cookies to personalize your browsing experience.
        </CookieConsent>
        <Sidebar history={history}>
          <Switch>
            <PrivateRoute
              restricted={true}
              path='/dashboard'
              component={FullLayout}
            />
            <PrivateRoute
              restricted={true}
              path='/orders'
              component={FullLayout}
            />
            <PrivateRoute
              restricted={true}
              path='/profile'
              component={FullLayout}
            />
            <PrivateRoute
              restricted={true}
              path='/my-measurements'
              component={FullLayout}
            />
            <PrivateRoute
              restricted={true}
              path='/posture-check'
              component={FullLayout}
            />
            <PrivateRoute
              restricted={true}
              path='/wardrobe'
              component={FullLayout}
            />
            <BrowserRoutes history={history} />
          </Switch>
        </Sidebar>
      </div>
    </Router>
  );
};

export default App;
