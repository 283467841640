import React from 'react';
import { Col, Row } from 'react-bootstrap';

import AnimatedEmptyBox from '../../ui-library/layout/elements/AnimatedEmptyBox';
import { Space } from '../../ui-library/space';
import StyledContainer from '../../ui-library/styled/StyledContainer';

const EmptyState = () => {
  return (
    <StyledContainer>
      <Row>
        <Col lg={8}>
          <AnimatedEmptyBox height='232px' />
        </Col>
        <Col lg={4}>
          <AnimatedEmptyBox />
          <Space size={8} />
          <AnimatedEmptyBox />
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default EmptyState;
