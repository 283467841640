import axios from 'axios';
import {
  FETCH_USER,
  PROFILE_UPDATE_FAIL,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
} from './auth.constants';

export const fetchUser = () => async (dispatch) => {
  await axios
    .get('/api/current_user')
    .then((res) => {
      dispatch({ type: FETCH_USER, payload: res.data });
    })
    .catch((error) => {
      console.log(error);
    });
};

/**
 * Updates profile of user with given data
 * payload could be one of the following. (Currently, it will be exclusively one of the following. We don't have a UI to send multiple profile updates in a single call)
 * { shippingAddress: {line1, country, ...} }
 * { billingAddress: {line1, country, ...} }
 * { jacketMeasurements: [{}, {}] }
 * { trouserMeasurements: [{}, {}] }
 * @param {Object} payload data to update in user profile
 */
export const updateUserProfile = (payload) => async (dispatch) => {
  try {
    dispatch({ type: PROFILE_UPDATE_REQUEST });
    const { data } = await axios.patch('/api/v1/profiles', payload);
    dispatch({ type: PROFILE_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: PROFILE_UPDATE_FAIL,
      payload: message,
    });
  }
};
