import { useState } from 'react';

import { requestResetPassword } from './local-auth.utils';

const useResetPassword = ({ setError }) => {
  // Used to show spinner when user has clicked login, signup or reset password
  const [isSendingResetPasswordEmail, setIsSendingResetPasswordEmail] =
    useState(false);

  // Requests for the 'reset password' email to be sent
  // Returns status of API call to caller
  // Currently, this is ResetPasswordForm's localSubmitHandler
  const handleSubmitResetPassword = async (values) => {
    setIsSendingResetPasswordEmail(true);
    setError(null);

    const status = await requestResetPassword(values);
    setIsSendingResetPasswordEmail(false);
    return status;
  };

  return {
    handleSubmitResetPassword,
    isSendingResetPasswordEmail,
  };
};

export default useResetPassword;
