import React from 'react';
import styled from 'styled-components';

import { Venus } from '../../../ui-library/typography';
import { hasAllMeasurements } from '../customize-product/measurement/measurement.utils';
import { hasAllPhotos } from '../customize-product/review-item/Photos/utils';
import { getWarningMessage } from './missing-items.utils';

const StyledWrapper = styled.div`
  margin: 8px 0;
  color: ${(props) => props.theme.colors.alert.main};
`;

const MissingItems = ({ measurements, photos }) => {
  const warning = getWarningMessage(measurements, photos);

  if (hasAllMeasurements(measurements) && hasAllPhotos(photos)) return null;

  return (
    <StyledWrapper>
      <Venus>{warning}</Venus>
    </StyledWrapper>
  );
};

export default MissingItems;
