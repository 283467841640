import useModalState from '../../../utils/useModalState';
import useNavigation from '../../../utils/useNavigation';
import { useIsUserLoggedIn } from '../../auth/auth.utils';
import LoginRegisterModal from '../../auth/LoginRegisterModal';

const useModalCheckout = () => {
  const [Modal, isOpen, show, hide] = useModalState(LoginRegisterModal);
  const isUserLoggedIn = useIsUserLoggedIn();
  const { goToCheckout } = useNavigation();

  const showLoginRegisterModal = () => {
    show();
  };
  const handleCheckout = () => {
    if (isUserLoggedIn) {
      goToCheckout();
    } else {
      showLoginRegisterModal();
    }
  };

  return {
    Modal,
    isOpen,
    show,
    hide,
    handleCheckout,
  };
};

export default useModalCheckout;
