import styled from 'styled-components';

const StyledBox = styled.div`
  display: flex;
  align-items: ${(props) => props.alignItems || 'start'};
  background-color: ${(props) => props.backgroundColor || 'transparent'};
  border-radius: ${(props) => props.borderRadius || 0};
  flex-basis: ${(props) => props.flexBasis || 'auto'};
  flex-direction: ${(props) => props.flexDirection || 'row'};
  flex-wrap: ${(props) => props.flexWrap || 'nowrap'};
  justify-content: ${(props) => props.justifyContent || 'start'};
  margin-bottom: ${(props) => props.marginBottom || 0};
  margin-left: ${(props) => props.marginLeft || 0};
  margin-right: ${(props) => props.marginRight || 0};
  margin-top: ${(props) => props.marginTop || 0};
  max-width: ${(props) => props.maxWidth || 'none'};
  padding: ${(props) => props.padding || 0};
`;
export default StyledBox;
