import {
  ADMIN_TAILOR_SHOP_LIST_FAIL,
  ADMIN_TAILOR_SHOP_LIST_REQUEST,
  ADMIN_TAILOR_SHOP_LIST_SUCCESS,
} from '../admin.constants';

const initialState = { list: [] };
const tailorShopReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_TAILOR_SHOP_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ADMIN_TAILOR_SHOP_LIST_SUCCESS:
      return {
        loading: false,
        list: action.payload,
      };
    case ADMIN_TAILOR_SHOP_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default tailorShopReducer;
