import React from 'react';
import { Form } from 'react-final-form';

import { Box } from '../../../../ui-library/layout';
import { Button } from '../../../../ui-library/buttons';
import FormTextField from '../../../../ui-library/FormTextField';
import FormErrorText from '../../../../ui-library/FormErrorText';
import { Gap } from '../../../../ui-library/space';
import { hasKey } from '../../../../utils/object';
import useCoupon from './useCoupon';

const CouponForm = ({ onCancel, onSubmit, isSaving, document }) => {
  const { coupon } = useCoupon();

  const validate = (values) => {
    const errors = {};
    if (!values.code || values.code.length < 1) {
      errors.code = 'Required';
    }

    return errors;
  };
  const showError = hasKey(coupon, 'error');

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={document}
      validate={validate}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          <Box marginBottom='32px' flexDirection='column'>
            <Box>
              <FormTextField
                name='code'
                title='Discount Code'
                variant='light'
              />
              <Gap size={4} />
              <Button
                isSecondary
                type='submit'
                disabled={submitting || pristine}
                margin='2px 0'
              >
                Apply
              </Button>
            </Box>
            <Box marginTop='4px' marginLeft='4px'>
              <FormErrorText showError={showError} text='Invalid Coupon Code' />
            </Box>
          </Box>
        </form>
      )}
    />
  );
};

export default CouponForm;
