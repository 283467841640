import { lazy } from 'react';
import AuthRoutes from './AuthRoutes';

//apps
const DashboardHomePage = lazy(() => import('../DashboardHomePage'));
const OrderPage = lazy(() => import('../../order/OrderPage'));
const OrderDetailPage = lazy(() => import('../../order/OrderDetailPage'));
const MyMeasurementsPage = lazy(() =>
  import('../../my-measurements/MyMeasurementsPage')
);
const PostureCheckPage = lazy(() =>
  import('../../posture-check/PostureCheckPage')
);
const ProfilePage = lazy(() => import('../../profile/ProfilePage'));
const WardrobePage = lazy(() => import('../../wardrobe/WardrobePage'));
const WardrobeItemPage = lazy(() => import('../../wardrobe/WardrobeItemPage'));
const auths = [].concat(AuthRoutes);

var ThemeRoutes = [
  {
    navlabel: true,
    name: 'Personal',
    icon: 'mdi mdi-dots-horizontal',
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'pie-chart',
    component: DashboardHomePage, //Modern,
  },
  {
    path: '/wardrobe/add',
    name: 'Genie',
    component: WardrobeItemPage,
    hidden: true,
  },
  {
    path: '/wardrobe/:itemId',
    name: 'Genie',
    component: WardrobeItemPage,
    hidden: true,
  },
  {
    path: '/wardrobe',
    name: 'Genie',
    component: WardrobePage,
    icon: 'briefcase',
    isCustomIcon: true,
  },
  {
    path: '/orders/:orderNumber',
    name: 'Orders',
    component: OrderDetailPage,
    hidden: true,
  },
  {
    path: '/orders',
    name: 'Orders',
    icon: 'package',
    component: OrderPage,
  },
  {
    navlabel: true,
    name: 'Apps',
    icon: 'mdi mdi-dots-horizontal',
  },
  {
    path: '/profile/account-details',
    name: 'Profile',
    icon: 'user',
    component: ProfilePage,
  },
  {
    path: '/profile/:section',
    name: 'Profile',
    component: ProfilePage,
    hidden: true,
  },
  {
    path: '/my-measurements/jackets',
    name: 'My Measurements',
    icon: 'edit',
    component: MyMeasurementsPage,
  },
  {
    path: '/my-measurements/:section',
    name: 'My Measurements',
    component: MyMeasurementsPage,
    hidden: true,
  },
  {
    path: '/posture-check',
    name: 'Posture Check',
    icon: 'camera',
    component: PostureCheckPage,
  },
  {
    path: '/',
    pathTo: '/dashboard',
    name: 'Dashboard',
    redirect: true,
  },
];
export default ThemeRoutes;
