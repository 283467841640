import axios from 'axios';

/**
 * @param {String} fabricId
 * @returns Fabric object if found, or null if not found
 */
export const getFabricById = async (fabricId) => {
  let fabric;
  await axios
    .get(`/api/v1/fabrics/${fabricId}`)
    .then((res) => {
      fabric = res.data;
    })
    .catch((error) => {
      // console.log('getFabricById error', error);
      fabric = null;
    });
  return fabric;
};

/**
 * @param {String[]} fabricIds
 * @returns array of Fabric objects for array of ids in fabricIds
 */
export const getFabricsByIds = async (fabricIds) => {
  let fabrics;
  await axios
    .get('/api/v1/fabrics', { params: { fabricIds } })
    .then((res) => {
      fabrics = res.data;
    })
    .catch((error) => {
      // console.log(error);
      fabrics = null;
    });
  return fabrics;
};
