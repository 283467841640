import React from 'react';
import StyledBaseText from '../styled/StyledBaseText';
import theme from '../../theme';
const { neptune } = theme.textStyles;

const Neptune = (props) => {
  return (
    <StyledBaseText {...neptune} {...props}>
      {props.children}
    </StyledBaseText>
  );
};

export default Neptune;
