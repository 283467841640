import React from 'react';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';

import { CenterContentBox } from './layout';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;
`;
const Loader = () => {
  return (
    <StyledWrapper>
      <CenterContentBox>
        <Spinner animation='border' />
      </CenterContentBox>
    </StyledWrapper>
  );
};

export default Loader;
