import {
  hasAllMeasurements,
  hasNoMeasurements,
} from '../customize-product/measurement/measurement.utils';
import {
  hasAllPhotos,
  hasNoPhotos,
} from '../customize-product/review-item/Photos/utils';

/**
 * Returns warning message depending on missing measurements and/or photos
 * 
 * LEGEND
 * M: Measurements
 * P: Photos
 * a: all exist
 * -: none exist
 * s: some exist
 * 
 * M   P   Message
 * a   a   null
 * -   -   Measurements and photos are missing
 * 
 * a   -   Photos are missing
 * a   s   Some photos are missing

 * -   a   Measurements are missing
 * s   a   Some measurements are missing
 * 
 * s   -   Some measurements and photos are missing
 * -   s   Some measurements and photos are missing
 * s   s   Some measurements and photos are missing
 * 
 * @param {Array} measurements 
 * @param {Array} photos 
 * @returns String
 */
export const getWarningMessage = (measurements, photos) => {
  if (hasAllMeasurements(measurements) && hasAllPhotos(photos)) return null;
  if (hasNoMeasurements(measurements) && hasNoPhotos(photos))
    return 'Measurements and photos are missing';

  if (hasAllMeasurements(measurements)) {
    if (hasNoPhotos(photos)) return 'Photos are missing';
    return 'Some photos are missing';
  }
  if (hasAllPhotos(photos)) {
    if (hasNoMeasurements(measurements)) return 'Measurements are missing';
    return 'Some measurements are missing';
  }

  return 'Some measurements and photos are missing';
};
