import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { Box } from '../../../../ui-library/layout';
import CartEditControls from './CartEditControls';
import { Mercury } from '../../../../ui-library/typography';
import MissingItems from '../MissingItems';
import Price from './Price';
import ProductDetails from './ProductDetails';
import ProductImage from './ProductImage';
import Quantity from './Quantity';

const StyledWrapper = styled(Box)`
  align-items: flex-end;

  @media (max-width: 441px) {
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
  }
`;

const GarmentCartListItem = ({ index, item, isEditable, onEdit, onRemove }) => {
  // Details of Row / Column setup
  // https://www.figma.com/file/JHyF0DVRXSIa5k819vk3ne/Concept-v1.0-(Copy)?node-id=412%3A2

  return (
    <Row noGutters className='p-2'>
      <Col xs={2}>
        <ProductImage item={item} />
      </Col>
      <Col xs={10}>
        <Row style={{ margin: 'auto', paddingLeft: '12px' }}>
          <Col xs={1} className='p-0'></Col>
          <Col xs={7} className='p-0'>
            <ProductDetails item={item} />
          </Col>
          <Col xs={2} className='p-0'></Col>
          <Col xs={2} className='p-0'>
            {isEditable && (
              <CartEditControls
                index={index}
                onEdit={onEdit}
                onRemove={onRemove}
              />
            )}
          </Col>
        </Row>
        <Row style={{ margin: '18px auto 0 auto', paddingLeft: '12px' }}>
          <Col xs={1} className='p-0'></Col>
          <Col xs={11} className='p-0'>
            <StyledWrapper justifyContent='space-between'>
              <Quantity index={index} item={item} isEditable={isEditable} />
              <Box flexDirection='column' alignItems='flex-end'>
                <Price item={item} />
                <div className='mt-1 ml-1'>
                  {item.patternPrice > 0 && (
                    <Mercury>(incl. $250 for pattern)</Mercury>
                  )}
                </div>
              </Box>
            </StyledWrapper>
            {isEditable && (
              <MissingItems
                measurements={item.measurements}
                photos={item.photos}
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default GarmentCartListItem;
