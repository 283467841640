import { combineReducers } from 'redux';
import authReducer from '../modules/auth/auth.reducers';
import adminReducer from '../modules/admin/admin.reducers';
import cartReducer from '../modules/store/cart/cart.reducers';
import couponReducer from '../modules/store/cart/coupon/coupon.reducers';
import orderReducer from '../modules/order/order.reducers';
import orderOverviewReducer from '../modules/order-overview/order-overview.reducers';
import productCustomizationReducer from '../modules/store/customize-product/productCustomization.reducers';
import {
  billingAddressReducer,
  shippingAddressReducer,
} from '../modules/store/checkout/checkout.reducers';
import uiReducer from '../modules/ui/ui.reducers';
import wardrobeReducer from '../modules/wardrobe/wardrobe.reducers';

export default combineReducers({
  auth: authReducer,
  admin: adminReducer,
  cart: cartReducer,
  coupon: couponReducer,
  orders: orderReducer,
  orderOverviews: orderOverviewReducer,
  productCustomization: productCustomizationReducer,
  shippingAddress: shippingAddressReducer,
  billingAddress: billingAddressReducer,
  ui: uiReducer,
  wardrobe: wardrobeReducer,
});
