import React from 'react';

import { CenterContentBox } from '../../../ui-library/layout';
import { Earth } from '../../../ui-library/typography';

const ResetPasswordEmailSentView = () => {
  return (
    <CenterContentBox margin='8px 0 16px 0' textAlign='center'>
      <Earth>
        We've sent you an email with details on how to reset your password.
      </Earth>
    </CenterContentBox>
  );
};

export default ResetPasswordEmailSentView;
