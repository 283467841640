import styled from 'styled-components';

const getHeight = (props) => `${props.size * 4}px`;
const getSmallHeight = (props) =>
  props.isFixed ? getHeight(props) : `${props.size * 2}px`;

const StyledSpace = styled.div`
  height: ${(props) => getHeight(props)};

  @media (max-width: 540px) {
    height: ${(props) => getSmallHeight(props)};
  }
`;
export default StyledSpace;
