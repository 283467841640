import React from 'react';
import { Button } from '../../ui-library/buttons';
import VerticalCenterContentBox from '../../ui-library/layout/elements/VerticalCenterContentBox';
import { Space } from '../../ui-library/space';
import StyledContainer from '../../ui-library/styled/StyledContainer';
import { Earth, Jackfruit } from '../../ui-library/typography';
import useNavigation from '../../utils/useNavigation';

const NotFoundPage = () => {
  const { goToHome } = useNavigation();
  return (
    <StyledContainer>
      <VerticalCenterContentBox>
        <Space size={8} />
        <Jackfruit>Page not found</Jackfruit>
        <Space size={8} />
        <Earth>We're sorry. That page does not exist</Earth>
        <Space size={4} />
        <Button isOutline isPrimary onClick={goToHome}>
          Back to Website
        </Button>
        <Space size={8} />
      </VerticalCenterContentBox>
    </StyledContainer>
  );
};

export default NotFoundPage;
