import {
  CHECKOUT_SAVE_BILLING_ADDRESS,
  CHECKOUT_SAVE_SHIPPING_ADDRESS,
} from './checkout.constants';

const shippingInitialState = {};
export const shippingAddressReducer = (
  state = shippingInitialState,
  action
) => {
  switch (action.type) {
    case CHECKOUT_SAVE_SHIPPING_ADDRESS:
      return action.payload;
    default:
      return state;
  }
};

const billingInitialState = {};
export const billingAddressReducer = (state = billingInitialState, action) => {
  switch (action.type) {
    case CHECKOUT_SAVE_BILLING_ADDRESS:
      return action.payload;
    default:
      return state;
  }
};
