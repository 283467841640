import styled from 'styled-components';

const StyledMobileWrapper = styled.div`
  margin: 0;
  display: none;

  @media (max-width: 912px) {
    display: block;
    width: 100%;
  }
`;

export default StyledMobileWrapper;
