import React from 'react';
import { Spinner } from 'react-bootstrap';

import { Box, CenterContentBox } from '../../layout';
import { Button } from '../index';

const LoadingButton = ({ isLoading, onClick, width, children, ...rest }) => {
  return (
    <CenterContentBox width={width || 'auto'}>
      {isLoading ? (
        <Box padding='16px 0'>
          <Spinner animation='border' size='sm' />
        </Box>
      ) : (
        <Button {...rest} onClick={onClick}>
          {children}
        </Button>
      )}
    </CenterContentBox>
  );
};

export default LoadingButton;
