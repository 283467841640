import axios from 'axios';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { isObject } from '../../utils/object';

const useAuth = () => {
  const history = useHistory();
  const user = useSelector((state) => state.auth);

  const isAdmin = isObject(user?.adminProfile);

  const isCustomer = isObject(user?.customerProfile) && !isAdmin;

  const isTailor = isObject(user?.tailorProfile);

  const logout = () => {
    axios.get('/api/logout').then(() => {
      history.push('/');
      window.location.reload();
    });
  };

  return {
    isAdmin,
    isCustomer,
    isTailor,
    logout,
    user,
  };
};

export default useAuth;
