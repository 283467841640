import styled from 'styled-components';

import { RoundedBorder } from '..';

const StyledVanishingBorder = styled(RoundedBorder)`
  padding: ${(props) => props.padding || '32px'};
  margin: ${(props) => props.margin || 0};

  @media (max-width: 540px) {
    border: 0;
    padding: 0;
  }
`;

export default StyledVanishingBorder;
