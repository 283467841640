import { isArray, isEmpty } from 'validate.js';
import { clone } from './object';

/**
 * Returns copy of an array of objects
 * @param {Array} items array of objects
 * @returns Array
 */
export const cloneArrayOfObjects = (arr) => arr.map((item) => clone(item));

/**
 * Returns an object where each key is a group of objects
 * @param {Array} objectArray
 * @param {String} property
 * @returns Object
 */
export const groupBy = (objectArray, property) => {
  if (!isArray || isEmpty(objectArray)) return {};
  if (isEmpty(property)) return {};

  return objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
};

/**
 * Returns index of object in list, where object match is done by provided 'key'
 *
 * Example usage:
 *  i = getIndexOfObjectInListByKey(order, list, 'orderNumber');
 *
 * Eg data:
 * order = {_id:'b', orderNumber:'98op'}
 * list = [
 *  {_id:'a', orderNumber:'7xbu'},
 *  {_id:'b', orderNumber:'98op'},
 * ]
 * key = 'orderNumber'
 *
 * This will return the second object from list
 *
 * @param {Object} object Object to find in array
 * @param {Array} list array to search object in
 * @param {String} key Key within object we are searching for
 * @returns
 */
export const getIndexOfObjectInListByKey = (object, list, key) => {
  return list.findIndex((x) => x[key] === object[key]);
};

/**
 * Returns object / item from the array at given index.
 * If index is out of bounds, returns null
 *
 * @param {Array} array
 * @param {Number} index index of object to retrieve
 * @returns Object
 */
export const getItemAtIndex = (array, index) => {
  if (!isArray || isEmpty(array)) return null;

  const hasValue = isValidIndex(array, index);
  return hasValue ? array[index] : null;
};

export const hasItemInList = (item, list) => list?.indexOf(item) !== -1;

/**
 * Returns the first item in a list that matches a given key-value pair.
 * @param {Array} list 
 * @param {String} key
 * @param {String} value
 */
export const getItemInListByKeyValue = ({ list = [], key, value }) =>
  list.find((x) => x[key] === value);

/**
 * Returns last item of given array
 * @param {Array} array
 * @returns last object (of any type)
 */
export const getLastItem = (array) => {
  if (!isArray || isEmpty(array)) return null;

  return array[array.length - 1];
};

/**
 * Returns true if index is within bounds of given array. Else returns false
 *
 * @param {Array} array
 * @param {Number} index index to check
 * @returns Boolean
 */
export const isValidIndex = (array, index) => {
  if (!isArray || isEmpty(array)) return false;

  return index < array.length;
};

/**
 * Returns array by updating originalArray with values from latestArray
 * Eg:
 * input
 * originalArray:[{title:'a', value:1}, {title:'b', value:2}]
 * latestArray:[{title:'a', value:99},{title:'e', value:1}]
 * searchKey:'title'
 * valueKey:'value'
 *
 * output
 * [{title:'a', value:99},{title:'a', value:2}]
 * NOTES:
 * - object.title 'a' has been updated because it has a value in latestArray
 * - object.title 'b' is NOT updated because latestArray has no corresponding value
 * - object.title 'e' is NOT inserted because it's not in originalArray
 *
 * @param {Array} originalArray array to update INTO
 * @param {Array} latestArray array to update FROM
 * @param {String} searchKey object key used to match objects
 * @param {String} valueKey key whose value must be updated
 * @returns Array
 */
export const updateArrayWithValuesFromArray = ({
  originalArray,
  latestArray,
  searchKey,
  valueKey,
}) => {
  // create a copy of originalArray
  let result = cloneArrayOfObjects(originalArray);

  // loop through latestArray
  for (let i = 0; i < latestArray.length; i++) {
    // check if latestArray[i] exists in result
    for (let j = 0; j < result.length; j++) {
      // compare by searchKey
      if (latestArray[i][searchKey] === result[j][searchKey]) {
        // update with valueKey
        result[j][valueKey] = latestArray[i][valueKey];
        break;
      }
    }
  }
  return result;
};

/**
 * Returns true if array contains string
 * @param array - Array to search
 * @param str - String to search
 * @returns a boolean value
 */
export const arrayContainsString = (array, str) => {
  if (!isArray(array)) { return false; }
  return array.indexOf(str) !== -1
}