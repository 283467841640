export const CART_ADD_ITEM = 'cart/CART_ADD_ITEM';
export const CART_REMOVE_ITEM = 'cart/CART_REMOVE_ITEM';
export const CART_DECREMENT_QUANTITY = 'cart/CART_DECREMENT_QUANTITY';
export const CART_INCREMENT_QUANTITY = 'cart/CART_INCREMENT_QUANTITY';
export const CART_UPDATE_QUANTITY = 'cart/CART_UPDATE_QUANTITY';
export const CART_DECREMENT_METERS = 'cart/CART_DECREMENT_METERS';
export const CART_INCREMENT_METERS = 'cart/CART_INCREMENT_METERS';
export const CART_UPDATE_METERS = 'cart/CART_UPDATE_METERS';
export const CART_UPDATE_FABRIC = 'cart/CART_UPDATE_FABRIC';
export const CART_UPDATE_MEASUREMENTS = 'cart/CART_UPDATE_MEASUREMENTS';
export const CART_UPDATE_OPTIONS = 'cart/CART_UPDATE_OPTIONS';
export const CART_UPDATE_UNIT_OF_MEASUREMENT =
  'cart/CART_UPDATE_UNIT_OF_MEASUREMENT';
export const CART_UPDATE_PHOTOS = 'cart/CART_UPDATE_PHOTOS';
export const CART_CLEAR_ALL = 'cart/CART_CLEAR_ALL';

export const SHIPPING_PRICE = 50;
