// Selecting Options
export const UI_BEGIN_SELECTING_OPTIONS = 'ui/UI_BEGIN_SELECTING_OPTIONS';
export const UI_END_SELECTING_OPTIONS = 'ui/UI_END_SELECTING_OPTIONS';

// Customizing Fabric
export const UI_BEGIN_CUSTOMIZING_FABRIC = 'ui/UI_BEGIN_CUSTOMIZING_FABRIC';
export const UI_END_CUSTOMIZING_FABRIC = 'ui/UI_END_CUSTOMIZING_FABRIC';

// Entering Measurements
export const UI_BEGIN_ENTERING_MEASUREMENTS =
  'ui/UI_BEGIN_ENTERING_MEASUREMENTS';
export const UI_END_ENTERING_MEASUREMENTS = 'ui/UI_END_ENTERING_MEASUREMENTS';

// Uploading Photos
export const UI_BEGIN_UPLOADING_PHOTOS = 'ui/UI_BEGIN_UPLOADING_PHOTOS';
export const UI_END_UPLOADING_PHOTOS = 'ui/UI_END_UPLOADING_PHOTOS';

// Review Item at the end of customization
export const UI_BEGIN_REVIEWING_ITEM = 'ui/UI_BEGIN_REVIEWING_ITEM';
export const UI_END_REVIEWING_ITEM = 'ui/UI_END_REVIEWING_ITEM';

// Tab Status
export const UI_SET_TAB_STATUS = 'ui/UI_SET_TAB_STATUS';

// Sidebar
export const UI_SHOW_SIDEBAR = 'ui/UI_SHOW_SIDEBAR';
export const UI_HIDE_SIDEBAR = 'ui/UI_HIDE_SIDEBAR';
