import React from 'react';
import { Col, Row } from 'react-bootstrap';
import theme from '../../theme';
import useSubcomponents from './useSubcomponents';

/**
 * Two column layout used in CartPage and CheckoutPage
 * They are full bleed and full window height
 * It's called Nintendo because the two columns next to each other look like Nintendo Switch. We can't call it Switch as it will conflict with the Router Switch component, so it's called Nintendo.
 */
const Nintendo = ({ children }) => {
  const { left, right } = useSubcomponents(children);

  return (
    <Row className='full-height' noGutters>
      {left}
      {right}
    </Row>
  );
};

// Cart page needs dark BG on the left, while checkout needs dark on the right
// Passing 'isDark' will use the dark BG on that column
const getStyle = (isDark) => {
  if (isDark) return { backgroundColor: theme.colors.grey.light };
  return { backgroundColor: theme.colors.white };
};
const Left = ({ children, isDark, width = 7 }) => (
  <Col md={width} style={getStyle(isDark)}>
    {children}
  </Col>
);
Left.displayName = 'Left';
Nintendo.Left = Left;

const Right = ({ children, isDark, width = 5 }) => (
  <Col md={width} style={getStyle(isDark)}>
    {children}
  </Col>
);
Right.displayName = 'Right';
Nintendo.Right = Right;

export default Nintendo;
