import {
  PC_CLEAR_CUSTOMIZATION,
  PC_CLEAR_OPTIONS,
  PC_SET_OPTION,
} from '../productCustomization.constants';

/*
Shape. This will have options for EITHER trousers OR jackets, depending on which product we are customizing at a given time

options: {
  trouserCuffs: true,
}

OR

options: {
  coatButtons: 3,
}
*/
const initialState = {};
const optionsReducer = (state = initialState, action) => {
  const option = action.payload;
  switch (action.type) {
    case PC_SET_OPTION:
      return { ...state, [option.key]: option.value };
    case PC_CLEAR_OPTIONS:
      return initialState;
    case PC_CLEAR_CUSTOMIZATION:
      return initialState;
    default:
      return state;
  }
};

export default optionsReducer;
