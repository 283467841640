import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { CountryDropdown } from 'react-country-region-selector';

const propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

const CountryField = ({ input, meta, disabled }) => {
  const getClassName = () => {
    // If there is an error, make border and arrow red
    if (meta.error && meta.touched) {
      return 'country-region-select-error full-width';
    }
    // If control is disabled
    if (disabled) {
      return 'country-region-select-disabled full-width';
    }
    // If user has chosen a value, change the font style
    if (input.value !== '') {
      return 'country-region-select-value-exists full-width';
    }
    // Default grey style (placeholder style)
    return 'country-region-select full-width';
  };
  return (
    <div className='full-width'>
      <CountryDropdown
        value={input.value}
        onBlur={input.onBlur}
        onFocus={input.onFocus}
        onChange={(value) => input.onChange(value)}
        className={getClassName()}
        disabled={disabled}
      />
    </div>
  );
};

const FormCountryField = ({ name, disabled }) => (
  <Field name={name} component={CountryField} disabled={disabled} />
);
FormCountryField.propTypes = propTypes;

export default FormCountryField;
