import {
  SHOP_MAKE_ONE_FOR_ME,
  SHOP_TAB_SELECTION,
  SHOP_PC_CUFF_OPTION,
  SHOP_PC_FABRIC_OPTION,
  SHOP_PC_FABRIC_OPTION_CONFIRM,
  SHOP_PC_ADD_TO_CART,
  SHOP_CART_EDIT_PRODUCT,
  SHOP_CART_REMOVE_PRODUCT,
  SHOP_CART_UPDATE_QUANTITY,
  SHOP_CART_CONTINUE_SHOPPING,
  SHOP_CART_CHECKOUT,
  SHOP_CHECKOUT_GO_TO_PAYMENT,
  SHOP_PAYMENT_PAY,
  SHOP_PAYMENT_BACK_TO_SHOP,
  SHOP_PAYMENT_ENTER_MEASUREMENTS,
} from './shop.tracking.constants';
import { track } from './tracking';

export const trackMakeOneForMe = (categorySlug, productSlug) => {
  track(SHOP_MAKE_ONE_FOR_ME, { categorySlug, productSlug });
};

export const trackTabSelection = (tab_label) => {
  track(SHOP_TAB_SELECTION, { tab_label });
};

export const trackCuffOption = (option_value) => {
  track(SHOP_PC_CUFF_OPTION, { option_value });
};

export const trackFabricOption = () => {
  track(SHOP_PC_FABRIC_OPTION);
};

export const trackFabricOptionConfirm = (fabric_id) => {
  track(SHOP_PC_FABRIC_OPTION_CONFIRM, { fabric_id });
};

export const trackAddToCart = () => {
  track(SHOP_PC_ADD_TO_CART);
};

export const trackEditProductInCart = () => {
  track(SHOP_CART_EDIT_PRODUCT);
};

export const trackRemoveProductFromCart = () => {
  track(SHOP_CART_REMOVE_PRODUCT);
};

export const trackUpdateQuantityInCart = (item_value) => {
  track(SHOP_CART_UPDATE_QUANTITY, { item_value });
};

export const trackContinueShopping = () => {
  track(SHOP_CART_CONTINUE_SHOPPING);
};

export const trackCartCheckout = () => {
  track(SHOP_CART_CHECKOUT);
};

export const trackCheckoutGoToPayment = () => {
  track(SHOP_CHECKOUT_GO_TO_PAYMENT);
};

export const trackPay = () => {
  track(SHOP_PAYMENT_PAY);
};

export const trackPaymentBackToShop = () => {
  track(SHOP_PAYMENT_BACK_TO_SHOP);
};

export const trackPaymentEnterMeasurements = () => {
  track(SHOP_PAYMENT_ENTER_MEASUREMENTS);
};
