import React from 'react';
import { Image } from 'react-bootstrap';
import styled from 'styled-components';

const getBorderRadius = (props) => {
  if (props.borderRadius) return props.borderRadius;
  if (props.size) {
    if (props.size === 'sm') return '4px';
  }
  return '12px';
};
const StyledCoverImage = styled(({ borderRadius, ...rest }) => (
  <Image {...rest} />
))`
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '100%'};
  object-fit: cover;
  border-radius: ${(props) => getBorderRadius(props)};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#faf7e2'};
  border: ${(props) => (props.border ? props.border : 0)};
`;
export default StyledCoverImage;
