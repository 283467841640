import produce from 'immer';
import {
  WARDROBE_HISTORICAL_DATA_FAIL,
  WARDROBE_HISTORICAL_DATA_REQUEST,
  WARDROBE_HISTORICAL_DATA_SUCCESS,
} from './wardrobe.constants';

const initialStateProfile = { loading: false };
const wardrobeReducer = produce((draft, action) => {
  const { payload } = action;
  switch (action.type) {
    case WARDROBE_HISTORICAL_DATA_REQUEST:
      draft.loading = true;
      break;
    case WARDROBE_HISTORICAL_DATA_SUCCESS:
      draft.historicalWardrobe = action.payload;
      draft.loading = false;
      break;
    case WARDROBE_HISTORICAL_DATA_FAIL:
      draft.loading = false;
      draft.error = payload;
      break;
  }
}, initialStateProfile);

export default wardrobeReducer;
