import {
  PC_CLEAR_CUSTOMIZATION,
  PC_DEFAULT_PATTERN_PRICE,
  PC_SET_PATTERN_PRICE,
} from '../productCustomization.constants';

const initialState = PC_DEFAULT_PATTERN_PRICE;
const patternPriceReducer = (state = initialState, action) => {
  switch (action.type) {
    case PC_SET_PATTERN_PRICE:
      return action.payload;
    case PC_CLEAR_CUSTOMIZATION:
      return PC_DEFAULT_PATTERN_PRICE;
    default:
      return state;
  }
};

export default patternPriceReducer;
