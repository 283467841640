import React from 'react';

import ShowMoreButtonDesktop from './ShowMoreButtonDesktop';
import ShowMoreButtonMobile from './ShowMoreButtonMobile';

const ShowMoreButton = ({ loading, isVisible, onClick }) => {
  if (!isVisible) return null;
  return (
    <div>
      <ShowMoreButtonDesktop loading={loading} onClick={onClick} />
      <ShowMoreButtonMobile loading={loading} onClick={onClick} />
    </div>
  );
};

export default ShowMoreButton;
