import React from 'react';
import { Col, Row } from 'react-bootstrap';

import FormTextField from '../../../../FormTextField';

const Measurements = ({ fields, measurements }) => {
  return (
    <Row>
      {fields.map((measurement, index) => (
        <Col md={4} key={measurement}>
          <div className='mb-4'>
            <FormTextField
              name={`${measurement}.value`}
              title={measurements[index].title}
              component='input'
              disabled
            />
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default Measurements;
