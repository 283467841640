import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { compose, createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import { ThemeProvider } from 'styled-components';
import theme from './ui-library/theme';

import './bootstrap.min.css';
import './modules/dashboard/assets/scss/style.scss';
import './index.css';
import App from './components/App';
import reducers from './reducers';
import { save, load } from 'redux-localstorage-simple';
import SentryFallback from './components/SentryFallback';

// Redux
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })
  : compose;

const persistStatesInNamespace = {
  ignoreStates: ['auth', 'admin', 'coupon', 'orders', 'orderOverviews', 'ui'],
  namespace: 'askokey_app',
};
const store = createStore(
  reducers,
  load(persistStatesInNamespace),
  composeEnhancers(applyMiddleware(reduxThunk, save(persistStatesInNamespace)))
);

// Sentry
Sentry.init({
  dsn: 'https://267d67fe24aa401bb95a381403ede605@o913964.ingest.sentry.io/5852242',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={<SentryFallback />}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </Sentry.ErrorBoundary>,
  document.querySelector('#root')
);
