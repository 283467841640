import React from 'react';
import StyledBaseText from '../styled/StyledBaseText';
import theme from '../../theme';
const { watermelon } = theme.textStyles;

const Watermelon = (props) => {
  return (
    <StyledBaseText {...watermelon} {...props}>
      {props.children}
    </StyledBaseText>
  );
};

export default Watermelon;
