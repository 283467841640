import React, { useState } from 'react';

import ResetPasswordEmailSentView from './ResetPasswordEmailSentView';
import RequestResetPasswordForm from './RequestResetPasswordForm';
import FormErrorText from '../../../ui-library/FormErrorText';
import LoginForm from './LoginForm';
import {
  shouldShowResetPasswordEmailSentUI,
  shouldShowRequestResetPasswordUI,
  shouldShowLoginUI,
  shouldShowSignupUI,
} from './local-auth.utils';
import SignupForm from './SignupForm';
import {
  trackLoginLinkClick,
  trackSubmitClick,
} from '../../tracking/onboarding.tracking';
import useCurrentUI from './useCurrentUI';
import useLocalAuth from './useLocalAuth';
import useResetPassword from './useResetPassword';
import {
  trackRequestResetPasswordClick,
  trackReturnToLoginClick,
} from '../../tracking/signin.tracking';

/**
 * Handles local auth
 *
 * currentUICallback: When called from LoginRegisterModal, the modal's title needs to change between Login and Signup. The local 'currentUI' state is channeled back to the parent through this callback
 *
 * @param {String} redirectURL Redirect to this URL after login/signup
 * @param {Function} onHide Modal parent's onHide
 * @param {String} initialUI AUTH_SIGNUP_UI or AUTH_LOGIN_UI
 * @param {Function} currentUICallback Modal parent's callback
 * @returns
 */
const LocalAuth = ({ redirectURL, onHide, initialUI, currentUICallback }) => {
  const [error, setError] = useState(null);
  const {
    currentUI,
    handleWantToLogin,
    handleWantToResetPassword,
    handleWantToSignup,
    handleSentResetPasswordEmail,
  } = useCurrentUI({ initialUI, currentUICallback, setError });
  const { handleSubmitLogin, handleSubmitSignup, isLoggingIn, isSigningUp } =
    useLocalAuth({ redirectURL, onHide, setError });
  const { handleSubmitResetPassword, isSendingResetPasswordEmail } =
    useResetPassword({ setError });

  const _handleSubmitResetPassword = (values) => {
    trackRequestResetPasswordClick();
    handleSubmitResetPassword(values);
  };
  const _handleSubmitSignup = (values) => {
    trackSubmitClick();
    handleSubmitSignup(values);
  };
  const _handleWantToLogin = () => {
    trackLoginLinkClick();
    handleWantToLogin();
  };
  const _handleWantToSignup = () => {
    trackReturnToLoginClick();
    handleWantToSignup();
  };

  return (
    <div>
      {error && <FormErrorText showError text={error} />}
      {shouldShowLoginUI(currentUI) && (
        <LoginForm
          onSubmit={handleSubmitLogin}
          onWantToResetPassword={handleWantToResetPassword}
          onWantToSignUp={_handleWantToSignup}
          isLoggingIn={isLoggingIn}
        />
      )}
      {shouldShowSignupUI(currentUI) && (
        <SignupForm
          onSubmit={_handleSubmitSignup}
          onWantToLogin={_handleWantToLogin}
          isSigningUp={isSigningUp}
        />
      )}
      {shouldShowRequestResetPasswordUI(currentUI) && (
        <RequestResetPasswordForm
          onSubmit={_handleSubmitResetPassword}
          onResetPasswordEmailSent={handleSentResetPasswordEmail}
          onWantToLogin={handleWantToLogin}
          isSendingResetPasswordEmail={isSendingResetPasswordEmail}
        />
      )}
      {shouldShowResetPasswordEmailSentUI(currentUI) && (
        <ResetPasswordEmailSentView />
      )}
    </div>
  );
};

export default LocalAuth;
