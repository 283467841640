// Product Page

// Duplicate of DSCV_PRODUCT_BTN_CLICK?
// export const SHOP_VIEW_PRODUCT_DETAILS =
// 'Customer-Session.Web-App.Shop-Home.View-Product-Details-BTN.click';
export const SHOP_MAKE_ONE_FOR_ME =
  'Customer-Session.Web-App.Shop-Product-Details.Make-One-For-Me-BTN.click';
export const SHOP_TAB_SELECTION =
  'Customer-Session.Web-App.Shop-Product-Details.Tab-Selection.click';

// Product Customization
export const SHOP_PC_CUFF_OPTION =
  'Customer-Session.Web-App.Shop-Product-Customize.Cuff-Option.click';
export const SHOP_PC_FABRIC_OPTION =
  'Customer-Session.Web-App.Shop-Product-Customize.Fabric-Option-BTN.click';
export const SHOP_PC_FABRIC_OPTION_CONFIRM =
  'Customer-Session.Web-App.Shop-Product-Customize.Fabric-Option-Confirm-BTN.click';
export const SHOP_PC_ADD_TO_CART =
  'Customer-Session.Web-App.Shop-Product-Customize.Add-To-Cart-BTN.click';

// Cart
export const SHOP_CART_EDIT_PRODUCT =
  'Customer-Session.Web-App.Shop-Cart.Edit-Product-Icon.click';
export const SHOP_CART_REMOVE_PRODUCT =
  'Customer-Session.Web-App.Shop-Cart.Remove-Product-Icon.click';
export const SHOP_CART_UPDATE_QUANTITY =
  'Customer-Session.Web-App.Shop-Cart.Update-Quantity.click';
export const SHOP_CART_CONTINUE_SHOPPING =
  'Customer-Session.Web-App.Shop-Cart.Continue-Shopping-BTN.click';
export const SHOP_CART_CHECKOUT =
  'Customer-Session.Web-App.Shop-Cart.Checkout-BTN.click';

// Checkout
// export const SHOP_CHECKOUT_ = 'Customer-Session.Web-App.Shop-Checkout.Use-Billing-Address-As-Shipping-Check.Click'
export const SHOP_CHECKOUT_GO_TO_PAYMENT =
  'Customer-Session.Web-App.Shop-Checkout.Pay-Now-BTN.Click';

// Payment
export const SHOP_PAYMENT_PAY =
  'Customer-Session.Web-App.Shop-Payment.Pay-BTN.Click';
export const SHOP_PAYMENT_BACK_TO_SHOP =
  'Customer-Session.Web-App.Shop-Payment-Confirmation.Back-To-Shop-BTN.Click';
export const SHOP_PAYMENT_ENTER_MEASUREMENTS =
  'Customer-Session.Web-App.Shop-Payment-Confirmation.Enter-Measurements-BTN.Click';
