import React from 'react';
import { useDispatch } from 'react-redux';

import CartListItem from './CartListItem/CartListItem';

import { removeItemFromCart, removeItemFromServerCart } from './cart.actions';
import useNavigation from '../../../utils/useNavigation';

const CartList = ({ cart, ...rest }) => {
  const dispatch = useDispatch();
  const { goToEditCartItemAtIndex } = useNavigation();

  const handleRemoveCartItem = (index) => {
    dispatch(removeItemFromCart(index));
    dispatch(removeItemFromServerCart(index));
  };

  const handleEditCartItem = (index) => {
    goToEditCartItemAtIndex(index);
  };

  return (
    <div>
      {cart.map((item, index) => (
        <CartListItem
          item={item}
          index={index}
          key={index}
          onEdit={handleEditCartItem}
          onRemove={handleRemoveCartItem}
          {...rest}
        />
      ))}
    </div>
  );
};

export default CartList;
