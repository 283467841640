import {
  FETCH_USER,
  PROFILE_UPDATE_FAIL,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
} from './auth.constants';

const initialState = null;
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER:
      /**
       * Server data will have the profile data populated into the customerProfile_id field
       * Locally, it's easier to call this just 'customerProfile'
       * So we insert data from customerProfile_id into the customerProfile key
       */
      if (!action.payload) return false;
      const { customerProfile_id, ...rest } = action.payload;
      return {
        ...rest,
        customerProfile: customerProfile_id,
        loading: false,
      };
    case PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        customerProfile: action.payload,
      };
    case PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PROFILE_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
