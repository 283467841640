// API constants
export const USER_ORDER_LIST_REQUEST = 'user/orders/USER_ORDER_LIST_REQUEST';
export const USER_ORDER_LIST_SUCCESS = 'user/orders/USER_ORDER_LIST_SUCCESS';
export const USER_ORDER_LIST_FAIL = 'user/orders/USER_ORDER_LIST_FAIL';

export const USER_ORDER_REQUEST = 'user/orders/USER_ORDER_REQUEST';
export const USER_ORDER_SUCCESS = 'user/orders/USER_ORDER_SUCCESS';
export const USER_ORDER_FAIL = 'user/orders/USER_ORDER_FAIL';

// Deprecated. Use ORDER_LIST_BY_STATUS_REQUEST , _SUCCESS, _FAIL instead
export const USER_UNOPENED_ORDER_LIST_REQUEST =
  'user/orders/USER_UNOPENED_ORDER_LIST_REQUEST';
export const USER_UNOPENED_ORDER_LIST_SUCCESS =
  'user/orders/USER_UNOPENED_ORDER_LIST_SUCCESS';
export const USER_UNOPENED_ORDER_LIST_FAIL =
  'user/orders/USER_UNOPENED_ORDER_LIST_FAIL';

export const USER_UNREAD_MESSAGES_ORDER_LIST_REQUEST =
  'user/orders/USER_UNREAD_MESSAGES_ORDER_LIST_REQUEST';
export const USER_UNREAD_MESSAGES_ORDER_LIST_SUCCESS =
  'user/orders/USER_UNREAD_MESSAGES_ORDER_LIST_SUCCESS';
export const USER_UNREAD_MESSAGES_ORDER_LIST_FAIL =
  'user/orders/USER_UNREAD_MESSAGES_ORDER_LIST_FAIL';

export const ORDER_LIST_BY_STATUS_REQUEST =
  'orders/ORDER_LIST_BY_STATUS_REQUEST';
export const ORDER_LIST_BY_STATUS_SUCCESS =
  'orders/ORDER_LIST_BY_STATUS_SUCCESS';
export const ORDER_LIST_BY_STATUS_FAIL = 'orders/ORDER_LIST_BY_STATUS_FAIL';


// Local update constants
export const USER_ORDER_ADD_MESSAGE = 'user/orders/USER_ORDER_ADD_MESSAGE';
export const ORDER_CONDENSER_STATUS = 'orders/ORDER_CONDENSER_STATUS';

// Admin constants
export const ADMIN_UNOPENED_FOR_TOO_LONG_ORDER_LIST_REQUEST='admin/orders/REQUEST_ADMIN_UNOPENED_FOR_TOO_LONG_ORDER_LIST';
export const ADMIN_MISSING_MEASUREMENTS_FOR_TOO_LONG_ORDER_LIST_REQUEST='admin/orders/REQUEST_ADMIN_MISSING_MEASUREMENTS_FOR_TOO_LONG_ORDER_LIST';
export const ADMIN_IN_PROGRESS_FOR_TOO_LONG_ORDER_LIST_REQUEST='admin/orders/REQUEST_ADMIN_IN_PROGRESS_FOR_TOO_LONG_ORDER_LIST';
export const ADMIN_UNOPENED_FOR_TOO_LONG_ORDER_LIST_SUCCESS='admin/orders/SUCCESS_ADMIN_UNOPENED_FOR_TOO_LONG_ORDER_LIST';
export const ADMIN_MISSING_MEASUREMENTS_FOR_TOO_LONG_ORDER_LIST_SUCCESS='admin/orders/SUCCESS_ADMIN_MISSING_MEASUREMENTS_FOR_TOO_LONG_ORDER_LIST';
export const ADMIN_IN_PROGRESS_FOR_TOO_LONG_ORDER_LIST_SUCCESS='admin/orders/SUCCESS_ADMIN_IN_PROGRESS_FOR_TOO_LONG_ORDER_LIST';
export const ADMIN_UNOPENED_FOR_TOO_LONG_ORDER_LIST_FAIL='admin/orders/FAIL_ADMIN_UNOPENED_FOR_TOO_LONG_ORDER_LIST';
export const ADMIN_MISSING_MEASUREMENTS_FOR_TOO_LONG_ORDER_LIST_FAIL='admin/orders/FAIL_ADMIN_MISSING_MEASUREMENTS_FOR_TOO_LONG_ORDER_LIST';
export const ADMIN_IN_PROGRESS_FOR_TOO_LONG_ORDER_LIST_FAIL='admin/orders/FAIL_ADMIN_IN_PROGRESS_FOR_TOO_LONG_ORDER_LIST';

// Pusher channel update constants
export const CHANNEL_USER_ORDER_UPDATED =
  'channel/orders/CHANNEL_USER_ORDER_UPDATED';
