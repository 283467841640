import { isArray, isEmpty } from 'validate.js';

/**
 * Returns true if photos array has NO photos
 * @param {Array} photos
 * @returns Boolean
 */
export const hasNoPhotos = (photos) => {
  let result = true; // has no photos by default

  photos.forEach((obj) => {
    if (obj.imageURL) {
      result = false;
    }
  });

  return result;
};

/**
 * Returns true if photos array has PARTIAL photos
 * @param {Array} photos
 * @returns Boolean
 */
export const hasPartialPhotos = (photos) =>
  !hasNoPhotos(photos) && !hasAllPhotos(photos);

/**
 * Returns true if photos array has ALL photos
 * @param {Array} photos
 * @returns Boolean
 */
export const hasAllPhotos = (photos) => {
  if (!isArray(photos) || isEmpty(photos)) return false;

  let result = true; // has all photos by default
  photos.forEach((obj) => {
    if (!obj.imageURL) {
      result = false;
    }
  });

  return result;
};
