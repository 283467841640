import styled from 'styled-components';
import { Box } from '..';

export const StyledColumn = styled.div`
  margin-top: 104px;
  max-width: ${(props) => props.maxWidthDesktop || '400px'};
  width: 100%;

  @media (max-width: 768px) {
    margin-top: 54px;
    max-width: ${(props) => props.maxWidthMobile || '648px'};
  }
`;
const StyledHugLeft = styled(Box)`
  justify-content: flex-start;
  margin: 64px 32px 0 32px;
`;

export default StyledHugLeft;
