/* eslint-disable */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Nav, Navbar, NavbarBrand } from 'reactstrap';
import * as data from './Data';

import { settings } from '../../settings';
import { LogoWhite } from '../../../../../ui-library/Logo';
import { Button } from '../../../../../ui-library/buttons';
import { Gap } from '../../../../../ui-library/space';
import useAuth from '../../../../auth/useAuth';

const Header = () => {
  const { logout } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  // const settings = useSelector((state) => state.settings);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const showMobilemenu = () => {
    document.getElementById('main-wrapper').classList.toggle('show-sidebar');
  };

  const sidebarHandler = () => {
    let element = document.getElementById('main-wrapper');
    switch (settings.activeSidebarType) {
      case 'full':
      case 'iconbar':
        element.classList.toggle('mini-sidebar');
        if (element.classList.contains('mini-sidebar')) {
          element.setAttribute('data-sidebartype', 'mini-sidebar');
        } else {
          element.setAttribute('data-sidebartype', settings.activeSidebarType);
        }
        break;

      case 'overlay':
      case 'mini-sidebar':
        element.classList.toggle('full');
        if (element.classList.contains('full')) {
          element.setAttribute('data-sidebartype', 'full');
        } else {
          element.setAttribute('data-sidebartype', settings.activeSidebarType);
        }
        break;
      default:
    }
  };

  return (
    <header className='topbar navbarbg' data-navbarbg={settings.activeNavbarBg}>
      <Navbar
        className={
          'top-navbar ' +
          (settings.activeNavbarBg === 'skin6' ? 'navbar-light' : 'navbar-dark')
        }
        expand='md'
      >
        <div
          className='navbar-header'
          id='logobg'
          data-logobg={settings.activeLogoBg}
        >
          {/*--------------------------------------------------------------------------------*/}
          {/* Mobile View Toggler  [visible only after 768px screen]                         */}
          {/*--------------------------------------------------------------------------------*/}
          <span
            className='nav-toggler d-block d-md-none'
            onClick={showMobilemenu.bind(null)}
            style={{ color: '#FFFEF4' }}
          >
            <i className='ti-menu ti-close' />
          </span>
          {/*--------------------------------------------------------------------------------*/}
          {/* Logos Or Icon will be goes here for Light Layout && Dark Layout                */}
          {/*--------------------------------------------------------------------------------*/}
          <NavbarBrand href='/'>
            <b className='logo-icon'>
              <LogoWhite />
            </b>
          </NavbarBrand>
          {/*--------------------------------------------------------------------------------*/}
          {/* Mobile View Toggler  [visible only after 768px screen]                         */}
          {/*--------------------------------------------------------------------------------*/}
          <span
            className='topbartoggler d-block d-md-none'
            onClick={toggle.bind(null)}
          >
            <i className='ti-more' />
          </span>
        </div>
        <Nav className='ml-auto float-right' navbar>
          <Button isSecondary isOutline size='sm' onClick={logout}>
            Sign out
          </Button>
          <Gap size={8} />
        </Nav>
      </Navbar>
    </header>
  );
};

export default Header;
