import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { isEmpty } from 'validate.js';

import { Box } from '../../../ui-library/layout';
import { Earth } from '../../../ui-library/typography';
import FormEmailField from '../../../ui-library/FormEmailField';
import { isValidEmail } from '../../../utils/validateEmails';
import { LoadingButton, TextButton } from '../../../ui-library/buttons';

const validate = (values) => {
  const errors = {};
  if (isEmpty(values.email) || !isValidEmail(values.email)) {
    errors.email = 'Required';
  }
  return errors;
};

const RequestResetPasswordForm = ({
  onSubmit,
  onResetPasswordEmailSent,
  onWantToLogin,
  initialValues,
  isSendingResetPasswordEmail,
}) => {
  const [error, setError] = useState(null);
  const localSubmitHandler = async (values) => {
    const status = await onSubmit(values);

    // If there was an error, display the error
    if (status?.error) {
      setError(status.error);
    } else {
      // Else, show the success message
      onResetPasswordEmailSent();
    }
  };

  if (error) {
    return <Earth>{error}</Earth>;
  }
  return (
    <div>
      <Form
        onSubmit={localSubmitHandler}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <FormEmailField name='email' title='Email' />
            <LoadingButton
              block
              isOutline
              type='submit'
              disabled={submitting || pristine}
              size='md'
              isLoading={isSendingResetPasswordEmail}
            >
              Send Reset Link
            </LoadingButton>
          </form>
        )}
      />
      <Box alignItems='center' justifyContent='center' marginTop='8px'>
        <TextButton onClick={onWantToLogin}>Return to Login</TextButton>
      </Box>
    </div>
  );
};

export default RequestResetPasswordForm;
