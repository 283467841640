import React from 'react';
import { Form } from 'react-final-form';

import { AddressFields } from '../../../../address-fields';
import { Box } from '../../../../layout';
import Modal from '../Modal';

const AddressModal = ({ isOpen, onHide, address }) => {
  return (
    <Modal isOpen={isOpen} onHide={onHide} title='Shipping Address'>
      <Form
        onSubmit={() => {}}
        validate={() => {
          return true;
        }}
        initialValues={address || []}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form onSubmit={handleSubmit}>
            <Box padding='32px 32px 0 32px'>
              <AddressFields values={address} disabled={true} />
            </Box>
          </form>
        )}
      />
    </Modal>
  );
};

export default AddressModal;
