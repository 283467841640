import {
  PC_CLEAR_CUSTOMIZATION,
  PC_SET_UNIT_OF_MEASUREMENT,
  PC_UNIT_CM,
} from '../productCustomization.constants';

const initialState = PC_UNIT_CM;
const unitOfMeasurementReducer = (state = initialState, action) => {
  switch (action.type) {
    case PC_SET_UNIT_OF_MEASUREMENT:
      return action.payload;
    case PC_CLEAR_CUSTOMIZATION:
      return PC_UNIT_CM;
    default:
      return state;
  }
};

export default unitOfMeasurementReducer;
