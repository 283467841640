import axios from 'axios';
import { isEmpty } from 'validate.js';

import {
  AUTH_LOGIN_UI,
  AUTH_RESET_PASSWORD_EMAIL_SENT_UI,
  AUTH_REQUEST_RESET_PASSWORD_UI,
  AUTH_SIGNUP_UI,
} from './local-auth.constants';

export const loginSignedUpUser = async (values) => {
  const { email, plainTextPassword } = values;
  return await axios
    .post('/auth/local/login', {
      username: email,
      password: plainTextPassword,
    })
    .then((res) => {
      return { success: true };
    })
    .catch((err) => {
      if (err.response.status === 401) {
        return { error: 'Invalid username or password' };
      } else {
        return { error: 'Unable to login. Please try again.' };
      }
    });
};

export const requestResetPassword = async (values) => {
  if (isEmpty(values)) return;
  const { email } = values;

  return await axios
    .post('/auth/local/request-reset-password', {
      email,
    })
    .catch((err) => {
      if (err.response.status === 409 || err.response.status === 500) {
        return { error: err.response.data.message };
      } else {
        return {
          error: 'Unable to send reset password email. Please try again.',
        };
      }
    });
};

export const resetPassword = async (values) => {
  const { userId, token, password } = values;

  return await axios
    .post('/auth/local/reset-password', {
      userId,
      token,
      password,
    })
    .then((res) => {
      return { success: true };
    })
    .catch((err) => {
      if (err.response.status === 409) {
        return { error: err.response.data.message };
      } else {
        return {
          error: 'Unable to reset password. Please try again.',
        };
      }
    });
};

export const signup = async (values) => {
  const { firstName, lastName, email, plainTextPassword } = values;

  return await axios
    .post('/auth/local/signup', {
      firstName,
      lastName,
      email,
      plainTextPassword,
    })
    .then(() => {
      return loginSignedUpUser(values);
    })
    .catch((err) => {
      if (err.response.status === 409) {
        return { error: err.response.data.message };
      } else {
        return { error: 'Unable to sign up. Please try again.' };
      }
    });
};

export const validateResetPasswordRequest = async ({ userId, token }) => {
  return await axios
    .post('/auth/local/validate-reset-password-request', {
      userId,
      token,
    })
    .then((res) => {
      return { success: true };
    })
    .catch((err) => {
      if (err.response.status === 409 || err.response.status === 500) {
        return { error: err.response.data.message };
      } else {
        return {
          error: 'Unable to reset password. Please try again.',
        };
      }
    });
};

// Decides whether login or signup UI should be displayed
export const shouldShowLoginUI = (currentUI) =>
  currentUI === AUTH_LOGIN_UI || isEmpty(currentUI);

export const shouldShowSignupUI = (currentUI) => currentUI === AUTH_SIGNUP_UI;

export const shouldShowRequestResetPasswordUI = (currentUI) =>
  currentUI === AUTH_REQUEST_RESET_PASSWORD_UI;

export const shouldShowResetPasswordEmailSentUI = (currentUI) =>
  currentUI === AUTH_RESET_PASSWORD_EMAIL_SENT_UI;
