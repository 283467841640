import React from 'react';
import { default as CSidebar } from 'react-sidebar';

import SidebarContent from './SidebarContent';
import useSidebar from './useSidebar';
import theme from '../../../ui-library/theme';

const sidebarStyle = {
  sidebar: {
    background: theme.colors.text.light,
    padding: '16px',
    width: '38%',
    minWidth: '320px',
    maxWidth: '550px',
  },
};

const Sidebar = ({ children }) => {
  const [isSidebarOpen, toggleSidebar] = useSidebar();

  return (
    <CSidebar
      sidebar={<SidebarContent />}
      open={isSidebarOpen}
      onSetOpen={toggleSidebar}
      pullRight
      styles={sidebarStyle}
    >
      {children}
    </CSidebar>
  );
};

export default Sidebar;
