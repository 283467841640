import React from 'react';

import { Box } from '../../../../layout';
import { Gap } from '../../../../space';
import { Mango } from '../../../../typography';

const UnitOfMeasurement = ({ unitOfMeasurement }) => {
  return (
    <div>
      <Box marginBottom='32px' alignItems='center'>
        <Mango>Unit:</Mango>
        <Gap size={2} /> {unitOfMeasurement}
      </Box>
    </div>
  );
};

export default UnitOfMeasurement;
