import axios from 'axios';
import { COUPON_CLEAR_COUPON, COUPON_SET_COUPON } from './coupon.constants';

export const setCouponCode = (code) => async (dispatch) => {
  try {
    const { data } = await axios.get(`/api/v1/coupons/code/${code}`);
    dispatch({ type: COUPON_SET_COUPON, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: COUPON_CLEAR_COUPON,
      payload: message,
    });
  }
};
