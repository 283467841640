import React from 'react';
import { Earth } from '../../../../typography';

const ProductOptions = ({ options }) => {
  return (
    <div>
      {Object.entries(options).map(([key, value]) => (
        <div key={key} className='mb-4'>
          <Earth>{key}</Earth>
          <b>{value}</b>
        </div>
      ))}
    </div>
  );
};

export default ProductOptions;
