import React from 'react';
import { Orange } from '../../../../ui-library/typography';
import { getCSN } from '../../../../utils/numbers';
import { getProductTotal } from '../cart.utils';

const Price = ({ item }) => {
  const total = getProductTotal(item);
  return <Orange>${getCSN(total)}</Orange>;
};

export default Price;
