import { useSelector } from 'react-redux';
import { isObject } from '../../utils/object';
import {
  shouldShowRequestResetPasswordUI,
  shouldShowResetPasswordEmailSentUI,
  shouldShowLoginUI,
  shouldShowSignupUI,
} from './local-auth/local-auth.utils';

/**
 * Returns Header text for given currentUI
 *
 * @param {String} currentUI
 * @returns String
 */
export const getHeaderText = (currentUI) => {
  if (shouldShowLoginUI(currentUI)) return 'Login';
  if (shouldShowSignupUI(currentUI)) return 'Signup';
  if (shouldShowRequestResetPasswordUI(currentUI)) return 'Reset your password';
  if (shouldShowResetPasswordEmailSentUI(currentUI)) return 'Email sent';

  return 'Login'; // Default, in case no option is specified
};

/**
 * Returns true if currentUI is either AUTH_LOGIN_UI or AUTH_SIGNUP_UI
 *
 * Essentially, will be false for AUTH_REQUEST_RESET_PASSWORD_UI and AUTH_RESET_PASSWORD_EMAIL_SENT_UI, since we don't want to show the Social Login buttons for those states
 * @param {String} currentUI
 * @returns Boolean
 */
export const shouldShowSocialLogin = (currentUI) =>
  shouldShowLoginUI(currentUI) || shouldShowSignupUI(currentUI);

/*
  Returns true if user is logged in
  @returns {Boolean} true if user is logged in. Else false.
*/
export const useIsUserLoggedIn = () => {
  const user = useSelector((state) => state.auth);
  return isObject(user);
};

