import { isEmpty, isString } from 'validate.js';
import validate from 'validate.js';

/**
 * Returns true if URL already has query params, ie, if it already has ?
 * Eg: /api/v1/orders?page=1
 * @param {String} url
 * @returns Boolean
 */
export const hasExistingParams = (url) => {
  if (isEmpty(url)) return false;
  if (!isString(url)) return false;

  return url.indexOf('?') > -1;
};

/**
 * Appends given param/value to given string, taking into account whether url already has existing params or not. Values will be URL encoded
 * Eg 1: If url does NOT have existing params
 * url='/api/v1/orders'
 * param = 'page'
 * value = 1
 *
 * Returns: '/api/v1/orders?page=1'
 *
 *
 * Eg 2: If url HAS existing params
 * url='/api/v1/orders?page=1'
 * param = 'filter'
 * value = 'something else'
 *
 * Returns: '/api/v1/orders?page=1&filter=something%20else'
 * @param {String} param
 * @param {Any} value
 * @param {String} url
 * @returns String
 */
export const appendParamToURL = (param, value, url) => {
  if (isEmpty(url) || isEmpty(param) || isEmpty(value)) return url;
  if (!isString(url) || !isString(param)) return url;

  const prefix = hasExistingParams(url) ? '&' : '?';
  const encodedValue = encodeURIComponent(value);
  const newURL = url + `${prefix}${param}=${encodedValue}`; // eg: ?page=5  or &filter=something%20else
  return newURL;
};

/**
 * Returns paginated URL for given combo of url, page and filter
 *
 * Eg: url='/api/v1/orders', page=1
 * Returns
 * '/api/v1/orders?page=1'
 * @param {String} url
 * @param {Number} page
 * @param {String} filter
 * @returns
 */
export const getPaginatedURL = ({ url: pURL, page, filter }) => {
  let url = pURL;
  url = appendParamToURL('page', page, url);
  url = appendParamToURL('filter', filter, url);
  return url;
};

/**
 * Returns pathname of given URL
 * @param {String} pURL the URL to parse
 * @returns String
 */
export const getPathname = pURL => {
  if (!isURL(pURL)) return null;

  const url = new URL(pURL);
  return url.pathname
}

/**
 * Returns true if given string is a valid URL
 * @param {String} url
 * @returns Boolean
 */
export const isURL = (url) =>
  typeof validate(
    { website: url },
    {
      website: {
        url: {
          allowLocal: true,
        },
      },
    }
  ) === 'undefined';