let prevPath = null;

const useAnalytics = (history) => {
  // First time load, register location '/'
  if (prevPath === null) {
    window.analytics.page();
  }

  // Every time location changes, register location
  history.listen((location) => {
    if (location.pathname !== prevPath) {
      prevPath = location.pathname;
      window.analytics.page();
    }
  });
};

export default useAnalytics;
