import React from 'react';
import theme from '../theme';

import { ReactComponent as AddSVG } from './assets/Add.svg';
import { ReactComponent as AddThickSVG } from './assets/AddThick.svg';
import { ReactComponent as AttachmentSVG } from './assets/Attachment.svg';
import { ReactComponent as BlankSVG } from './assets/Blank.svg';
import { ReactComponent as ButtonCloseFilledSVG } from './assets/ButtonCloseFilled.svg';
import { ReactComponent as ButtonCloseSVG } from './assets/ButtonClose.svg';
import { ReactComponent as CartSVG } from './assets/Cart.svg';
import { ReactComponent as CloseSVG } from './assets/Close.svg';
import { ReactComponent as EditCartItemSVG } from './assets/EditCartItem.svg';
import { ReactComponent as ExclamationSVG } from './assets/Exclamation.svg';
import { ReactComponent as LeftArrowSmallSVG } from './assets/LeftArrowSmall.svg';
import { ReactComponent as LeftArrowThickSVG } from './assets/LeftArrowThick.svg';
import { ReactComponent as MeasurementSVG } from './assets/Measurement.svg';
import { ReactComponent as NeedleSVG } from './assets/Needle.svg';
import { ReactComponent as OrdersSVG } from './assets/Orders.svg';
import { ReactComponent as OrderOptionsSVG } from './assets/OrderOptions.svg';
import { ReactComponent as OrderFabricsSVG } from './assets/OrderFabrics.svg';
import { ReactComponent as OrderPostureSVG } from './assets/OrderPosture.svg';
import { ReactComponent as RemoveCartItemSVG } from './assets/RemoveCartItem.svg';
import { ReactComponent as RightArrowSVG } from './assets/RightArrow.svg';
import { ReactComponent as RightArrowThickSVG } from './assets/RightArrowThick.svg';
import { ReactComponent as RightArrowSmallSVG } from './assets/RightArrowSmall.svg';
import { ReactComponent as RightArrowInCircleSVG } from './assets/RightArrowInCircle.svg';
import { ReactComponent as SubtractSVG } from './assets/Subtract.svg';
import { ReactComponent as TickSVG } from './assets/Tick.svg';
import { ReactComponent as TickInGreenCircleSVG } from './assets/TickInGreenCircle.svg';
import { ReactComponent as WarningTriangleSVG } from './assets/WarningTriangle.svg';

const AddIcon = () => <AddSVG />;
const AddThickIcon = () => <AddThickSVG />;
const AttachmentIcon = () => <AttachmentSVG />;
const BlankIcon = () => <BlankSVG />;
const ButtonCloseFilledIcon = () => <ButtonCloseFilledSVG />;
const ButtonCloseIcon = () => <ButtonCloseSVG />;

const CartIcon = () => <CartSVG fill={theme.colors.text.dark} />;
const CartIconLight = () => <CartSVG fill={theme.colors.text.main} />;
const CartIconWhite = () => <CartSVG fill={theme.colors.white} />;
const CloseIcon = () => <CloseSVG />;

const EditCartItemIcon = () => <EditCartItemSVG />;
const ExclamationIcon = () => <ExclamationSVG />;
const LeftArrowSmallIcon = () => <LeftArrowSmallSVG />;
const LeftArrowThickIcon = () => <LeftArrowThickSVG />;
const MeasurementIcon = () => <MeasurementSVG fill={theme.colors.white} />;

const OrderMeasurementIcon = () => (
  <MeasurementSVG fill={theme.colors.secondary.light} />
);
const OrderMeasurementIconDisabled = () => (
  <MeasurementSVG fill={theme.colors.secondary.light50} />
);
const OrderMeasurementIconSelected = () => (
  <MeasurementSVG fill={theme.colors.blue} />
);
const NeedleIcon = () => <NeedleSVG />;
const OrdersIcon = () => <OrdersSVG />;

const OrderCartIcon = () => <CartSVG fill={theme.colors.secondary.light} />;
const OrderCartIconDisabled = () => (
  <CartSVG fill={theme.colors.secondary.light50} />
);
const OrderCartIconSelected = () => <CartSVG fill={theme.colors.blue} />;

const OrderOptionsIcon = () => (
  <OrderOptionsSVG fill={theme.colors.secondary.light} />
);
const OrderOptionsIconDisabled = () => (
  <OrderOptionsSVG fill={theme.colors.secondary.light50} />
);
const OrderOptionsIconSelected = () => (
  <OrderOptionsSVG fill={theme.colors.blue} />
);

const OrderFabricsIcon = () => (
  <OrderFabricsSVG fill={theme.colors.secondary.light} />
);
const OrderFabricsIconDisabled = () => (
  <OrderFabricsSVG fill={theme.colors.secondary.light50} />
);
const OrderFabricsIconSelected = () => (
  <OrderFabricsSVG fill={theme.colors.blue} />
);

const OrderPostureIcon = () => (
  <OrderPostureSVG fill={theme.colors.secondary.light} />
);
const OrderPostureIconDisabled = () => (
  <OrderPostureSVG fill={theme.colors.secondary.light50} />
);
const OrderPostureIconSelected = () => (
  <OrderPostureSVG fill={theme.colors.blue} />
);

const RemoveCartItemIcon = () => <RemoveCartItemSVG />;
const RightArrowIcon = () => <RightArrowSVG />;
const RightArrowThickIcon = () => <RightArrowThickSVG />;
const RightArrowSmallIcon = () => <RightArrowSmallSVG />;
const RightArrowInCircleIcon = () => <RightArrowInCircleSVG />;
const SubtractIcon = () => <SubtractSVG />;
const TickIcon = () => <TickSVG />;
const TickInGreenCircleIcon = () => <TickInGreenCircleSVG />;
const WarningTriangleIcon = (props) => <WarningTriangleSVG {...props} />;

export { AddIcon };
export { AddThickIcon };
export { AttachmentIcon };
export { BlankIcon };
export { ButtonCloseFilledIcon };
export { ButtonCloseIcon };
export { CartIcon };
export { CartIconLight };
export { CartIconWhite };
export { CloseIcon };
export { EditCartItemIcon };
export { ExclamationIcon };
export { LeftArrowSmallIcon };
export { LeftArrowThickIcon };
export { MeasurementIcon };
export { NeedleIcon };
export { OrdersIcon };
export { OrderCartIcon };
export { OrderCartIconDisabled };
export { OrderCartIconSelected };
export { OrderMeasurementIcon };
export { OrderMeasurementIconDisabled };
export { OrderMeasurementIconSelected };
export { OrderOptionsIcon };
export { OrderOptionsIconDisabled };
export { OrderOptionsIconSelected };
export { OrderFabricsIcon };
export { OrderFabricsIconDisabled };
export { OrderFabricsIconSelected };
export { OrderPostureIcon };
export { OrderPostureIconDisabled };
export { OrderPostureIconSelected };
export { RemoveCartItemIcon };
export { RightArrowIcon };
export { RightArrowThickIcon };
export { RightArrowSmallIcon };
export { RightArrowInCircleIcon };
export { SubtractIcon };
export { TickIcon };
export { TickInGreenCircleIcon };
export { WarningTriangleIcon };
