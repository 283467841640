import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import FormErrorText from './FormErrorText';

// Floating label logic taken from here:
// https://dev.to/rafacdomin/creating-floating-label-placeholder-for-input-with-reactjs-4m1f

const propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
};

const FormPasswordField = ({ name, title, disabled }) => {
  const hasValue = (input) => input.value !== '';

  return (
    <Field
      name={name}
      subscribe={{ touched: true, error: true }}
      render={({ input, meta }) => {
        const shouldShowError = meta.error && meta.touched;

        return (
          <div className='form-field-container '>
            <input
              type='password'
              disabled={disabled}
              value={input.value}
              onBlur={input.onBlur}
              onFocus={input.onFocus}
              onChange={input.onChange}
              className={
                shouldShowError
                  ? 'form-field-input-error'
                  : 'form-field-input-regular'
              }
            />
            <label
              className={hasValue(input) ? 'form-field-input-value-exists' : ''}
            >
              <div className='form-field-label-background'>{title || name}</div>
            </label>
            <FormErrorText showError={shouldShowError} text={meta.error} />
          </div>
        );
      }}
    />
  );
};

FormPasswordField.propTypes = propTypes;

export default FormPasswordField;
