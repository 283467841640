import React, { useEffect } from 'react';
import { trackErrorPagePresented } from '../modules/tracking/session.tracking';
import StyledContainer from '../ui-library/styled/StyledContainer';
import { Earth, Jackfruit } from '../ui-library/typography';

const SentryFallback = () => {
  useEffect(() => {
    trackErrorPagePresented();
  }, []);

  return (
    <StyledContainer>
      <Jackfruit fontSize='48px'>Sorry!</Jackfruit>
      <Earth>Something went wrong. Our team has been notified.</Earth>
      <Earth>Please reload the page and try again.</Earth>
    </StyledContainer>
  );
};

export default SentryFallback;
