import React from 'react';
import NumberFormat from 'react-number-format';
import styled from 'styled-components';

import { AddIcon, SubtractIcon } from '../../../ui-library/icons';
import { Button } from '../../../ui-library/buttons';
import { Box } from '../../../ui-library/layout';
import { Earth, Orange } from '../../../ui-library/typography';
import { Gap } from '../../../ui-library/space';
import {
  decrementMetersForProductAtIndex,
  decrementMetersForProductOnServerCartAtIndex,
  incrementMetersForProductAtIndex,
  incrementMetersForProductOnServerCartAtIndex,
  updateMetersForProductAtIndex,
  updateMetersForProductOnServerCartAtIndex,
} from '../cart/cart.actions';
import { useDispatch } from 'react-redux';

const StyledBox = styled(Box)`
  align-items: center;
  margin-right: 8px;

  @media (max-width: 441px) {
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 16px;
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;

  background-color: #f1f0e0;
  border: 1px solid #c9c8be;
  border-radius: 4px;

  padding: 0;
  max-height: 26px;
`;
const StyledButtonWrapper = styled.div`
  margin-top: -3px;
  width: 26px;
  text-align: center;
`;

const numberStyle = {
  borderColor: 'transparent',
  backgroundColor: '#f1f0e0',
  fontSize: '14px',
  maxWidth: '42px',
  maxHeight: '24px',
  margin: 0,
  textAlign: 'center',
};

const FabricMeters = ({
  meters,
  setMeters,
  index,
  isEditable,
  title = 'Meters : ',
}) => {
  const dispatch = useDispatch();
  const handleChange = (value) => {
    const valueToUse = value < 1 ? 1 : value;
    dispatch(updateMetersForProductAtIndex(valueToUse, index));
    dispatch(updateMetersForProductOnServerCartAtIndex(valueToUse, index));
    setMeters(valueToUse);
  };
  const handleIncrement = () => {
    dispatch(incrementMetersForProductAtIndex(index));
    dispatch(incrementMetersForProductOnServerCartAtIndex(index));
    setMeters(meters + 0.5);
  };
  const handleDecrement = () => {
    dispatch(decrementMetersForProductAtIndex(index));
    dispatch(decrementMetersForProductOnServerCartAtIndex(index));
    let newVal = meters - 0.5;
    if (newVal < 1) newVal = 1;
    setMeters(newVal);
  };
  return (
    <StyledBox>
      <Earth lineHeight='20px'>{title}</Earth>
      <Gap size={2} />
      {isEditable ? (
        <StyledWrapper>
          <StyledButtonWrapper>
            <Button block icon={<SubtractIcon />} onClick={handleDecrement} />
          </StyledButtonWrapper>
          <NumberFormat
            value={meters}
            isAllowed={(values) => {
              const { floatValue } = values;
              return floatValue > 0;
            }}
            onChange={(e) => {
              handleChange(e.target.value);
            }}
            style={numberStyle}
          />
          <StyledButtonWrapper>
            <Button block icon={<AddIcon />} onClick={handleIncrement} />
          </StyledButtonWrapper>
        </StyledWrapper>
      ) : (
        <Orange>{meters}</Orange>
      )}
    </StyledBox>
  );
};

export default FabricMeters;
