import React from 'react';
import { useDispatch } from 'react-redux';

import { Box, RoundedBorder } from '../../../ui-library/layout';
import CouponForm from './coupon/CouponForm';
import { Earth } from '../../../ui-library/typography';
import { getCSN } from '../../../utils/numbers';
import ProductPrice from '../../../ui-library/ProductPrice';
import PriceRow from './PriceRow';
import { setCouponCode } from './coupon/coupon.actions';
import useCalculateCartTotal from './useCalculateCartTotal';
import useCart from './useCart';

const CartTotal = ({ showCouponForm = false }) => {
  const { cart } = useCart();
  const dispatch = useDispatch();
  const amount = useCalculateCartTotal(cart);

  const bShowDiscountRow = amount.discountAmount > 0;

  const handleSubmit = (values) => {
    dispatch(setCouponCode(values.code));
  };
  return (
    <>
      {showCouponForm && <CouponForm onSubmit={handleSubmit} />}
      <RoundedBorder>
        <PriceRow title='Subtotal' value={getCSN(amount.cartTotal)} />
        <PriceRow title='Shipping' value={amount.shipping} />
        {bShowDiscountRow && (
          <PriceRow title='Discount' value={amount.discountAmount} />
        )}
      </RoundedBorder>
      <Box
        justifyContent='space-between'
        alignItems='center'
        marginTop='42px'
        marginBottom='32px'
      >
        <Earth>Total</Earth>
        <ProductPrice price={amount.grandTotalAfterDiscount} />
      </Box>
    </>
  );
};

export default CartTotal;
