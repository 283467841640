import {
  UI_BEGIN_SELECTING_OPTIONS,
  UI_END_SELECTING_OPTIONS,
  UI_BEGIN_CUSTOMIZING_FABRIC,
  UI_BEGIN_ENTERING_MEASUREMENTS,
  UI_BEGIN_UPLOADING_PHOTOS,
  UI_END_CUSTOMIZING_FABRIC,
  UI_END_ENTERING_MEASUREMENTS,
  UI_BEGIN_REVIEWING_ITEM,
  UI_END_REVIEWING_ITEM,
  UI_SET_TAB_STATUS,
  UI_END_UPLOADING_PHOTOS,
  UI_HIDE_SIDEBAR,
  UI_SHOW_SIDEBAR,
} from './ui.constants';

// Selecting Options
export const beginSelectingOptions = () => async (dispatch) => {
  dispatch({ type: UI_BEGIN_SELECTING_OPTIONS });
};
export const endSelectingOptions = () => async (dispatch) => {
  dispatch({ type: UI_END_SELECTING_OPTIONS });
};

// Customizing Fabric
export const beginCustomizingFabric = () => async (dispatch) => {
  dispatch({ type: UI_BEGIN_CUSTOMIZING_FABRIC });
};
export const endCustomizingFabric = () => async (dispatch) => {
  dispatch({ type: UI_END_CUSTOMIZING_FABRIC });
};

// Entering Measurements
export const beginEnteringMeasurements = () => async (dispatch) => {
  dispatch({ type: UI_BEGIN_ENTERING_MEASUREMENTS });
};
export const endEnteringMeasurements = () => async (dispatch) => {
  dispatch({ type: UI_END_ENTERING_MEASUREMENTS });
};

// Tab Status
// Set the whole array of tabStatus
export const setTabsStatus = (tabsStatus) => async (dispatch) => {
  dispatch({ type: UI_SET_TAB_STATUS, payload: tabsStatus });
};

// Uploading Photos
export const beginUploadingPhotos = () => async (dispatch) => {
  dispatch({ type: UI_BEGIN_UPLOADING_PHOTOS });
};
export const endUploadingPhotos = () => async (dispatch) => {
  dispatch({ type: UI_END_UPLOADING_PHOTOS });
};

// Review Item at the end of product customization
export const beginReviewingItem = () => async (dispatch) => {
  dispatch({ type: UI_BEGIN_REVIEWING_ITEM });
};
export const endReviewingItem = () => async (dispatch) => {
  dispatch({ type: UI_END_REVIEWING_ITEM });
};

// Sidebar
export const showSidebar = () => async (dispatch) => {
  dispatch({ type: UI_SHOW_SIDEBAR });
};
export const hideSidebar = () => async (dispatch) => {
  dispatch({ type: UI_HIDE_SIDEBAR });
};
