import {
  PC_CLEAR_CUSTOMIZATION,
  PC_SET_MEASUREMENTS,
} from '../productCustomization.constants';

const initialState = [];
const measurementsReducer = (state = initialState, action) => {
  switch (action.type) {
    case PC_SET_MEASUREMENTS:
      return [...action.payload];
    case PC_CLEAR_CUSTOMIZATION:
      return [];
    default:
      return state;
  }
};

export default measurementsReducer;
