import styled from 'styled-components';
import { Box } from '..';

const StyledGasBox = styled(Box)`
  flex-direction: row;

  @media (max-width: ${(props) => props.maxWidth || '991px'}) {
    flex-direction: column;
    gap: ${(props) => props.gap || '8px'};
  }
`;

export default StyledGasBox;
